/**
 * Styled component used to display metadata in a sidebar within AssetDetail.
 */

import styled from "styled-components";
import { utils } from "@thenounproject/lingo-core";

const { getColor } = utils;

const AssetMetaTable = styled.table`
  border: 1px solid ${getColor("grayLight")};
  color: ${getColor("grayDarkest")};
  width: 100%;
  text-align: left;
  table-layout: fixed;

  & td {
    text-align: right;
  }

  & th {
    width: 85px;
  }

  & th,
  td {
    padding: 8px;
    font-weight: normal;
  }

  & tr:nth-child(odd) {
    background: ${getColor("white")};
  }

  & tr:nth-child(even) {
    background: ${getColor("grayLightest")};
  }

  & .ellipsis-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default AssetMetaTable;
