import React from "react";
import styled from "styled-components";

import { Flex, Box, AnyObject, utils } from "@thenounproject/lingo-core";

const { getFont, positioningCSS } = utils;

export const AuthContainer = styled(Box).attrs({
  width: "100%",
  maxWidth: "1200px",
  m: "0 auto",
  variations: {
    "mq.s_up": {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
})``;

export const AuthContent: React.FC<{
  withHeader?: boolean;
  styleOverrides?: AnyObject;
  children: React.ReactNode;
}> = ({ children, withHeader, styleOverrides = {} }) => {
  return (
    <Flex
      px="l"
      // Padding pushes content below the fixed header
      pt={withHeader ? "80px" : "20px"}
      justifyContent="center"
      alignItems="center"
      // 174 is the height of the footer
      minHeight="calc(100vh - 174px)"
      maxWidth="1200px"
      mx="auto"
      flexWrap="wrap"
      variations={{
        "mq.s": {
          // The extra 40px accounts for the poweredByLingo padge stacking in the footer
          minHeight: `calc(100vh - ${126 + (withHeader ? 0 : 40)}px)`,
        },
      }}
      {...styleOverrides}>
      {children}
    </Flex>
  );
};

export const AuthFormWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Flex
      justifyContent="center"
      flexDirection="column"
      width="400px"
      maxWidth="100%"
      style={{ overflowX: "hidden" }}>
      {children}
    </Flex>
  );
};

export const authButtonStyle = {
  mb: "s",
  mt: "xl",
  fullWidth: true,
};

export const AuthFormMessage = styled(Flex).attrs({
  justifyContent: "center",
  textAlign: "center",
  pt: "s",
})`
  span {
    ${getFont("ui.small", null)}
    a {
      ${positioningCSS({ ml: "xs" })}
    }
  }
`;
