import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  assetId: string;
  linkId: string;
};

const [useDeleteAssetDirectLink, deleteAssetDirectLink] = createAsyncAction(
  "assets/deleteDirectLink",
  async ({ assetId, linkId }: Args) => {
    const response = await API.call<number>({
      endpoint: `assets/${assetId}/direct_links/${linkId}`,
      method: "DELETE",
      entity: API.Entity.directLink,
    });
    return response;
  }
);

export default useDeleteAssetDirectLink;
export { deleteAssetDirectLink };
