import React, { ComponentProps } from "react";
import { ChromePicker, ColorResult } from "react-color";
import {
  PopupMenu,
  Flex,
  Text,
  Button,
  Tooltip,
  Box,
  buildPath,
  SpacePermission,
  ItemType,
  KitPermission,
  PortalPermission,
  Kit,
  Portal,
  utils,
} from "@thenounproject/lingo-core";
import styled, { useTheme } from "styled-components";

import { useSelectSpace } from "@selectors/entities/spaces";
import useUpsell, { AccessStatus } from "@hooks/useUpsell";
import UpsellTooltip from "../../../components/spaces/settings/UpsellTooltip";
import useNavPoint from "@hooks/useNavPoint";
import useConfigureMode from "../../../components/hooks/useConfigureMode";

const StyledColorPicker = styled(ChromePicker)`
  width: 100% !important;
`;

function kitPortalContext(kit: Kit, portal: Portal) {
  const canEditKit = kit && kit.access.permissions.includes(KitPermission.manageCustomTheme);
  const canEditPortal =
    !canEditKit &&
    !kit &&
    portal &&
    portal.access.permissions.includes(PortalPermission.manageCustomTheme);

  return { canEditKit, canEditPortal };
}

type NoteProps = {
  editItemBackground: (background: string | null, itemType?: ItemType) => void;
  selectedColor: string;
  hPos?: ComponentProps<typeof PopupMenu.Item>["hPos"];
};

const { getColor } = utils;

export const NoteBackgroundColorOptions: React.FC<NoteProps> = ({
  editItemBackground,
  selectedColor,
  hPos,
}) => {
  const space = useSelectSpace();
  const theme = useTheme();
  const titleMap = {
    backgroundColor: "Background color",
    none: "None",
    info: "Info",
    warning: "Warning",
    success: "Success",
    custom: "Custom",
  };
  const themeAccess = useUpsell(SpacePermission.manageCustomTheme);

  const { toggleConfigureMode } = useConfigureMode();

  const { portal, kit } = useNavPoint();
  const { canEditKit, canEditPortal } = kitPortalContext(kit, portal);
  const canEditTheme =
      canEditKit || canEditPortal || themeAccess.accessStatus !== AccessStatus.insufficientRole,
    mustUpgrade =
      !canEditKit && !canEditPortal && themeAccess.accessStatus === AccessStatus.insufficientPlan;

  const themeEditorButtonProps: Partial<ComponentProps<typeof Button>> = {};
  if (canEditKit) {
    themeEditorButtonProps.onClick = toggleConfigureMode;
  } else if (canEditPortal) {
    themeEditorButtonProps.onClick = toggleConfigureMode;
  } else {
    themeEditorButtonProps.link = buildPath("/settings/editor?view=gallery", { space });
  }

  return (
    <PopupMenu.Item key={titleMap.backgroundColor} title={titleMap.backgroundColor} hPos={hPos}>
      <PopupMenu.Item
        key={titleMap.none}
        title={titleMap.none}
        onClick={() => editItemBackground(null, ItemType.note)}
        image={{ color: "transparent" }}
      />
      <PopupMenu.Item
        key={titleMap.info}
        title={titleMap.info}
        onClick={() => editItemBackground("noteInfoColor", ItemType.note)}
        image={{
          color: theme?.active && theme.noteInfoColor ? theme.noteInfoColor : getColor("noteInfo"),
        }}
      />
      <PopupMenu.Item
        key={titleMap.warning}
        title={titleMap.warning}
        onClick={() => editItemBackground("noteWarningColor", ItemType.note)}
        image={{
          color:
            theme?.active && theme.noteWarningColor
              ? theme.noteWarningColor
              : getColor("noteWarning"),
        }}
      />
      <PopupMenu.Item
        key={titleMap.success}
        title={titleMap.success}
        onClick={() => editItemBackground("noteSuccessColor", ItemType.note)}
        image={{
          color:
            theme?.active && theme.noteSuccessColor
              ? theme.noteSuccessColor
              : getColor("noteSuccess"),
        }}
      />
      <PopupMenu.Item
        key={titleMap.custom}
        title={titleMap.custom}
        image={{ iconId: "content.custom-colors", fill: "", size: "16" }}>
        <Box
          onClick={e => {
            e.stopPropagation();
          }}>
          <StyledColorPicker
            color={selectedColor}
            onChangeComplete={(color: ColorResult) => editItemBackground(color.hex, ItemType.note)}
            disableAlpha
          />
        </Box>
      </PopupMenu.Item>
      {canEditTheme && (
        <PopupMenu.Section data-tooltip-source="theme-editor-button">
          <Flex background="grayLightest" p="8px" alignItems="center" flexDirection="column">
            <Text font="ui.small" textAlign="center">
              Configure preset colors in the
            </Text>
            <Button
              text="theme editor"
              buttonStyle="tertiary"
              size="small"
              disabled={mustUpgrade}
              {...themeEditorButtonProps}
            />
          </Flex>
          {mustUpgrade && (
            <UpsellTooltip
              onClick={themeAccess.openUpgradeModal}
              source="theme-editor-button"
              featureName="Custom themes"
              direction={Tooltip.Direction.Top}
            />
          )}
        </PopupMenu.Section>
      )}
    </PopupMenu.Item>
  );
};

type AssetProps = {
  editAssetBackground: (background: string | null) => void;
  selectedColor: string;
  hPos: ComponentProps<typeof PopupMenu.Item>["hPos"];
};
export const AssetBackgroundColorOptions: React.FC<AssetProps> = ({
  editAssetBackground,
  selectedColor,
  hPos,
}) => {
  const { space, kit, portal } = useNavPoint();
  const { toggleConfigureMode } = useConfigureMode(),
    currentTheme = useTheme();
  const themeAccess = useUpsell(SpacePermission.manageCustomTheme);

  const titleMap = {
    backgroundColor: "Background color",
    light: "Light (default)",
    dark: "Dark",
    checkered: "Checkered",
    none: "None",
    custom: "Custom",
  };

  const activeCustomTheme = space?.theme?.active;

  const { canEditKit, canEditPortal } = kitPortalContext(kit, portal);
  const canEditTheme =
      canEditKit || canEditPortal || themeAccess.accessStatus !== AccessStatus.insufficientRole,
    mustUpgrade =
      !canEditKit && !canEditPortal && themeAccess.accessStatus === AccessStatus.insufficientPlan;

  const themeEditorButtonProps: Partial<ComponentProps<typeof Button>> = {};
  if (canEditKit) {
    themeEditorButtonProps.onClick = toggleConfigureMode;
  } else if (canEditPortal) {
    themeEditorButtonProps.onClick = toggleConfigureMode;
  } else {
    themeEditorButtonProps.link = buildPath("/settings/editor?view=gallery", { space });
  }

  return (
    <PopupMenu.Item key={titleMap.backgroundColor} title={titleMap.backgroundColor} hPos={hPos}>
      <PopupMenu.Item
        key={titleMap.none}
        title={titleMap.none}
        onClick={() => editAssetBackground("clear")}
        checked={selectedColor === "clear"}
        image={{ color: "transparent" }}
      />
      <PopupMenu.Item
        key={titleMap.light}
        title={titleMap.light}
        onClick={() => editAssetBackground("light")}
        checked={selectedColor === "light"}
        image={{
          color:
            activeCustomTheme && currentTheme.assetLightColor
              ? currentTheme.assetLightColor
              : getColor("grayLighter"),
        }}
      />
      <PopupMenu.Item
        key={titleMap.dark}
        title={titleMap.dark}
        onClick={() => editAssetBackground("dark")}
        checked={selectedColor === "dark"}
        image={{
          color:
            activeCustomTheme && currentTheme.assetDarkColor
              ? currentTheme.assetDarkColor
              : getColor("grayDark"),
        }}
      />

      <PopupMenu.Item
        key={titleMap.checkered}
        title={titleMap.checkered}
        onClick={() => editAssetBackground("checkered")}
        checked={selectedColor === "checkered"}
        image={{ iconId: "content.checkered", fill: "gray", size: "16" }}
      />
      <PopupMenu.Item
        key={titleMap.custom}
        title={titleMap.custom}
        checked={selectedColor.startsWith("#")}
        image={{ iconId: "content.custom-colors", fill: "", size: "16" }}>
        <Box
          onClick={e => {
            e.stopPropagation();
          }}>
          <StyledColorPicker
            color={selectedColor}
            onChangeComplete={(color: ColorResult) => editAssetBackground(color.hex)}
            disableAlpha
          />
        </Box>
      </PopupMenu.Item>
      {canEditTheme && (
        <PopupMenu.Section data-tooltip-source="theme-editor-button">
          <Flex background="grayLightest" p="8px" alignItems="center" flexDirection="column">
            <Text font="ui.small" textAlign="center">
              Configure preset colors in the
            </Text>
            <Button
              text="theme editor"
              buttonStyle="tertiary"
              size="small"
              disabled={mustUpgrade}
              {...themeEditorButtonProps}
            />
          </Flex>
          {mustUpgrade && (
            <UpsellTooltip
              onClick={themeAccess.openUpgradeModal}
              source="theme-editor-button"
              featureName="Custom themes"
              direction={Tooltip.Direction.Top}
            />
          )}
        </PopupMenu.Section>
      )}
    </PopupMenu.Item>
  );
};
