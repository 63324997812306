import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  Flex,
  Box,
  Icon,
  PopupMenu,
  ItemType,
  Text,
  Space,
  getCustomDomainComponents,
  Badge,
  utils,
} from "@thenounproject/lingo-core";

import { InsertType } from "../../constants/InsertType";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useCreateFileAssets from "@redux/actions/items/useCreateFileAssets";
import useCreateAssetsFromSource, {
  AssetSourceVendors,
} from "@redux/actions/items/useCreateAssetsFromSource";
import { type InsertPosition } from "@actions/uploads";
import { useSelectSpace } from "@selectors/entities/spaces";
import useGoogleDrivePicker from "@hooks/useGoogleDrivePicker";
import useDropboxPicker from "@hooks/useDropboxPicker";

const MenuItem = styled.button`
  display: flex;
  padding: 8px 16px;
  background: transparent;

  transition: background 0.3s ease;

  &:hover {
    background: ${utils.getColor("grayLighter")};
  }
`;

type Props = {
  insertPosition: InsertPosition;
  // If showing manually from a source (should not be provided with coordinates)
  sourceId?: string;
  coordinates?: { xPos: number | string; yPos: number | string };
  close: () => void;
};

type Context = { space: Space; insertPosition: InsertPosition };

const InsertAssetMenu: React.FC<Props> = ({
  insertPosition,
  coordinates,
  close,
  sourceId = "insert-asset-menu",
}) => {
  const space = useSelectSpace();

  const { showModal } = useShowModal(),
    [createFileAssets] = useCreateFileAssets(),
    [createAssetsFromSource] = useCreateAssetsFromSource();

  const onSelectFiles = useCallback(
    files => createFileAssets({ files, itemType: ItemType.asset, insertPosition }),
    [createFileAssets, insertPosition]
  );

  const onPickGoogleFont = useCallback(
    (names: string[]) => {
      const sources = names.map(name => ({
        name,
        source: AssetSourceVendors.GoogleFonts,
      }));
      return createAssetsFromSource({ sources, insertPosition });
    },
    [createAssetsFromSource, insertPosition]
  );

  const _openGooglePicker = useGoogleDrivePicker();
  const openGooglePicker = useCallback(() => {
    _openGooglePicker({
      multiselect: true,
      onSuccess: sources => {
        void createAssetsFromSource({ sources, insertPosition });
      },
    });
  }, [_openGooglePicker, createAssetsFromSource, insertPosition]);

  const _openDropboxPicker = useDropboxPicker();
  const openDropboxPicker = useCallback(() => {
    _openDropboxPicker({
      multiselect: true,
      onSuccess: sources => {
        void createAssetsFromSource({ sources, insertPosition });
      },
    });
  }, [_openDropboxPicker, createAssetsFromSource, insertPosition]);

  const assetMenuItems = useMemo(() => {
    const { host } = getCustomDomainComponents(),
      hideGoogleImport = Boolean(host);
    return [
      {
        type: InsertType.file,
        title: "File",
        icon: "content.file",
        label: "Add images, videos, docs and more",
        modal: ModalTypes.PICK_FILE,
      },
      {
        type: InsertType.color,
        title: "Color",
        icon: "content.color",
        label: "Add digital and print color values",
        modal: ModalTypes.CREATE_EDIT_COLOR,
      },
      {
        type: InsertType.font,
        title: "Font",
        icon: "content.font",
        label: "Add font files or use google fonts",
        modal: ModalTypes.CREATE_FONT,
      },
      {
        type: InsertType.link,
        title: "Link",
        icon: "action.link",
        label: "Add links to external resources",
        modal: ModalTypes.CREATE_EDIT_LINK,
      },
      {
        title: "-",
        isHidden: ({ space }: Context) => {
          return (
            !insertPosition.kitId ||
            (!space.access.permissions.includes("create_gallery_items") &&
              !space.access.permissions.includes("view_asset_library"))
          );
        },
      },
      {
        type: InsertType.gallery,
        isHidden: ({ space }: Context) => {
          return (
            !!insertPosition.itemId ||
            !(
              insertPosition.kitId &&
              space?.access?.permissions?.includes("create_gallery_items") &&
              space?.features?.includes("gallery_items")
            )
          );
        },
        title: "Gallery",
        icon: "content.grid",
        label: "Add a gallery to contain your assets",
        modal: ModalTypes.CREATE_GALLERY_ITEM,
        betaBadge: true,
      },
      {
        type: InsertType.library,
        title: "From Library",
        icon: "marketing.storage",
        label: "Add assets from your library",
        modal: ModalTypes.LIBRARY_PICKER,
        isHidden: ({ space, insertPosition }: Context) => {
          return !(
            insertPosition.kitId && space?.access?.permissions?.includes("view_asset_library")
          );
        },
      },
      {
        title: "-",
      },
      {
        type: InsertType.figma,
        title: "Figma",
        icon: "integrations.figma",
        label: "Add assets from a Figma file",
        modal: ModalTypes.FIGMA_LINK_MODAL,
      },
      {
        type: "GOOGLE",
        isHidden: () => hideGoogleImport,
        title: "Google Drive",
        label: "Import assets from Google drive",
        icon: "integrations.google-drive",
        onClick: openGooglePicker,
      },
      {
        type: "DROPBOX",
        title: "Dropbox",
        label: "Import assets from Dropbox",
        icon: "integrations.dropbox",
        onClick: openDropboxPicker,
      },
    ];
  }, [openDropboxPicker, openGooglePicker, insertPosition]);

  const menuItemModalProps = {
    [InsertType.file]: { onUploadFiles: onSelectFiles, itemType: ItemType.asset, multiple: true },
    [InsertType.font]: { onPickFontFiles: onSelectFiles, onPickGoogleFont },
    [InsertType.color]: { insertPosition },
    [InsertType.figma]: { insertPosition },
    [InsertType.library]: { insertPosition },
    [InsertType.link]: { insertPosition },
    [InsertType.gallery]: { insertPosition },
  };

  const menuItems = useMemo(
    () => assetMenuItems.filter(item => item.isHidden?.({ space, insertPosition }) !== true),
    [assetMenuItems, insertPosition, space]
  );

  const menu = (
    <PopupMenu
      width={320}
      source={sourceId}
      close={close}
      vPos="floatBelow"
      hPos="alignLeft"
      maxHeight={500}>
      <Text py="xs" px="m" font="ui.smallBold" color="grayDarkest">
        Select asset type
      </Text>
      {menuItems.map((item, idx) => {
        if (item.title === "-") {
          return (
            <Box
              flexShrink="0"
              key={`divider-${idx}`}
              width="100%"
              background="border"
              height="1px"
            />
          );
        }

        return (
          <MenuItem
            data-testid={"asset-menu-item-".concat(item.type).toLowerCase()}
            key={item.type}
            onClick={
              item.onClick ?? (() => showModal(item.modal, menuItemModalProps[item.type] || {}))
            }>
            <Flex
              borderRadius="default"
              flexShrink="0"
              background="grayLighter"
              justifyContent="center"
              alignItems="center"
              height="40px"
              width="40px"
              mr="s">
              <Icon iconId={item.icon} />
            </Flex>
            <Flex flexDirection="column" textAlign="left">
              <Flex alignItems="center">
                <Text font="ui.regular">{item.title}</Text>
                {item.betaBadge && (
                  <Badge id="beta-badge" text="Beta" styleOverrides={{ ml: "s" }} />
                )}
              </Flex>
              <Text font="ui.small" color="grayDarkest">
                {item.label}
              </Text>
            </Flex>
          </MenuItem>
        );
      })}
    </PopupMenu>
  );

  return coordinates ? (
    <PopupMenu.ContextMenuAnchor data-popup-source={sourceId} {...coordinates}>
      {menu}
    </PopupMenu.ContextMenuAnchor>
  ) : (
    menu
  );
};

export default InsertAssetMenu;
