import { DirectLink } from "@thenounproject/lingo-core";
import _merge from "lodash/merge";
import _remove from "lodash/remove";
import createEntityReducer from "../helpers/createEntityReducer";
import { createDirectLink } from "@redux/actions/assets/useCreateAssetDirectLink";
import { fetchAssetDirectLinks } from "@redux/actions/assets/useAssetDirectLinks";
import { deleteAssetDirectLink } from "@redux/actions/assets/useDeleteAssetDirectLink";

export default createEntityReducer<DirectLink>(
  "directLinks",
  queryBuilder => {
    queryBuilder
      .addCase(createDirectLink.fulfilled, (state, action) => {
        const newDirectLink = action.payload.entities.directLinks[action.payload.result];
        fetchAssetDirectLinks.getQueryData(state, { assetId: newDirectLink.assetId }).forEach(q => {
          q.data.directLinks.push(action.payload.result);
        });
      })
      .addCase(deleteAssetDirectLink.fulfilled, (state, action) => {
        const deletedDirectLink = action.payload.entities.directLinks[action.payload.result];
        fetchAssetDirectLinks
          .getQueryData(state, { assetId: deletedDirectLink.assetId })
          .forEach(q => {
            _remove(q.data.directLinks, id => id === deletedDirectLink.id);
          });
      });
  },
  objectBuilder => {
    objectBuilder.addDefaultCase((state, action: any) => {
      if (action.payload?.entities?.directLinks) {
        _merge(state, action.payload.entities.directLinks);
      }
    });
  }
);
