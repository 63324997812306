/**
 * Container component for kit content overview.
 * Handles fetching required data & passing down to presentational components.
 *
 * TODO: poll for changes to current section.
 * TODO: render modified banner if section has changed
 */

import React, { useEffect, useCallback, useRef, useMemo } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import {
  buildPath,
  Flex,
  Box,
  Button,
  ItemType,
  elementIds,
  Kit,
  KitVersion,
  Section,
  Item,
  useNavigation,
} from "@thenounproject/lingo-core";

import { NavPointTypes, buildItemUrl } from "@redux/legacy-actions/navPoints";

import KitNav from "../navigation/KitNav";
import MobileSubNav from "../navigation/MobileSubNav";
import { SubNavContainer } from "../navigation/SubNav";

import KitTitle from "./KitTitle";
import SectionComponent from "./Section";
import GalleryComponent from "./DynamicGallery";
import ManualGalleryComponent from "./ManualGallery";
import ContentContainer from "../ContentContainer";
import InspectorView from "../inspector/InspectorView";
import { InspectorSource } from "@constants/Inspector";
import { handleInspectorItemClick } from "@helpers/items";
import InsertPanel from "./InsertPanel";
import EmptyState from "../EmptyState";
import { useBannerContext } from "@contexts/BannerProvider";
import KitRecentlyDeletedItems from "./KitRecentlyDeletedItems";
import { NavPoint } from "@redux/legacy-actions/navPoints";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useGetAssets, useGetItems, useGetModals } from "@selectors/getters";
import KitRecoveredAssets from "./KitRecoveredItems";

import { TabletDown, TabletUp } from "@features/media-queries";
import useContentTypes from "@actions/useFetchContentTypes";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";

import { useSelectSection } from "@redux/selectors/entities/sections";
import { useClearInspectorOnClick, useShiftKey } from "@helpers/selection";
import { ThemeEditorSidePanel, ThemePreviewContentWrapper } from "../portals/Portal";
import KitThemeControlPanel from "@features/theming/components/KitThemeControlPanel";
import { OverflowWrapperPreview } from "@features/theming/pages/SpaceThemeEditor";
import useNavPoint from "@hooks/useNavPoint";
import useConfigureMode from "../hooks/useConfigureMode";

const ScrollContainer = styled(Box).attrs({
  id: elementIds.SCROLL_CONTAINER,
  width: "100%",
  height: "100%",
})`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

type Props = {
  kit: Kit;
  section?: Section;
  kitVersion?: KitVersion;
  versionNum: number;
  navPoint: NavPoint;
};

export enum GalleryContext {
  kit = "kit",
  deleted = "deleted",
  recovered = "recovered",
}

export enum PageContentTypes {
  section = "section",
  gallery = "gallery",
  empty = "empty",
  recoveredAssets = "recoveredAssets",
  trashedItems = "trashedItems",
}

const KitComponent: React.FC<Props> = ({ kit, kitVersion, versionNum, section, navPoint }) => {
  const { space, portal } = useNavPoint();
  const hasPortalsFeature = space.features.includes("portals");
  const { configureMode } = useConfigureMode();

  // Kit content CRUD permission
  const canEditContent = useMemo(
    () => kit?.access?.permissions?.includes("edit_content") && versionNum === 0,
    [kit, versionNum]
  );

  const assets = useGetAssets();
  const allItems = useGetItems();
  const { activeSpaceAlert } = useBannerContext();

  // Pre-load acceptable file types for direct drag and drop
  useContentTypes(
    {
      itemType: ItemType.asset,
    },
    { skip: !canEditContent }
  );

  const showTrashedItems = useMemo(() => {
    return (
      navPoint.type === NavPointTypes.KitTrashedItems ||
      (navPoint.type === "Item" && navPoint.galleryContext === "deleted")
    );
  }, [navPoint]);

  const firstSection = useSelectSection(kitVersion?.sections?.[0]);

  const displaySection = useMemo(() => {
    let s = section;
    if ([NavPointTypes.KitTrashedItems, NavPointTypes.KitRecoveredAssets].includes(navPoint.type)) {
      return null;
    }
    if (!s && kitVersion && kitVersion.sections[0]) {
      s = firstSection;
    }
    return s;
  }, [firstSection, kitVersion, navPoint.type, section]);

  const pageContentType = useMemo(() => {
    if (showTrashedItems) return PageContentTypes.trashedItems;
    if (navPoint.type === NavPointTypes.KitRecoveredAssets) return PageContentTypes.recoveredAssets;
    if (navPoint.type === NavPointTypes.Gallery) return PageContentTypes.gallery;
    if (navPoint.type === NavPointTypes.Item && navPoint.gallery) return PageContentTypes.gallery;
    return displaySection ? PageContentTypes.section : PageContentTypes.empty;
  }, [displaySection, navPoint.gallery, navPoint.type, showTrashedItems]);

  const navigate = useNavigation();
  const { showModal } = useShowModal();

  // Kit inspector state
  const {
    selectItems,
    clearSelection,
    selectionState: { kitGallery: selectedItems },
  } = useSelectedItemsContext();

  const selectedKitItems = useMemo(
      () =>
        selectedItems
          .map(id => allItems[id])
          .filter(i => i?.status === (showTrashedItems ? "trashed" : "active")),
      [allItems, selectedItems, showTrashedItems]
    ),
    inspectables = useMemo(
      () =>
        selectedKitItems.map(i => {
          const asset = assets[i.assetId];
          return {
            item: { ...i, asset },
            asset,
          };
        }),
      [assets, selectedKitItems]
    );

  const setInspectorItemIds = useCallback(
    (ids: string[]) => selectItems({ type: "kitGallery", ids }),
    [selectItems]
  );
  const shiftDown = useShiftKey();
  const disableTextSelection = shiftDown && selectedItems.length > 0;
  const cachedSelectedItems = useRef<string[]>(null);
  const scrollContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!shiftDown) cachedSelectedItems.current = null;
  }, [shiftDown]);

  const onSelectItem = useCallback(
    (event: React.MouseEvent, itemId: string, allItems: Item[]) => {
      let selectedBeforeClick = selectedItems;
      if (event?.shiftKey) {
        if (!cachedSelectedItems.current || cachedSelectedItems.current.length === 0) {
          cachedSelectedItems.current = selectedItems;
        }
        selectedBeforeClick = cachedSelectedItems.current;
      }
      handleInspectorItemClick(event, itemId, allItems, selectedBeforeClick, setInspectorItemIds);
    },
    [selectedItems, setInspectorItemIds]
  );

  const setInspectorState = useCallback(
    (state: string[]) => {
      setInspectorItemIds(state);
      cachedSelectedItems.current = state;
    },
    [setInspectorItemIds]
  );

  const clearInspector = useCallback(() => {
    clearSelection({ type: "kitGallery" });
    cachedSelectedItems.current = null;
  }, [clearSelection]);

  useClearInspectorOnClick(clearInspector);

  // Watch for kit deletion
  useEffect(() => {
    if (kit && kit.status === "deleted") {
      navigate.push(buildPath("/", { space: { id: space.id } }));
    }
  }, [navigate, kit, space.id]);

  const displayKey = `${displaySection?.id}-${pageContentType}`;

  // Watch for kit change
  useEffect(() => {
    clearInspector();
  }, [kit.kitId, versionNum, displayKey, clearInspector]);

  // Scroll to top when changing sections
  useEffect(() => {
    const scrollContainer = document.getElementById("inspector-view-wrapper");
    try {
      window.scrollTo(0, 0);
      scrollContainer.scrollTo(0, 0);
    } catch {
      // If this fails ¯\_(ツ)_/¯
    }
  }, [displayKey]);

  // Adding new sections
  const openNewSectionModal = useCallback(() => {
    showModal(ModalTypes.CREATE_SECTION, { kitId: kit.kitId, space });
  }, [showModal, kit.kitId, space]);

  const openShareModal = useCallback(() => {
    if (portal) {
      showModal(ModalTypes.SHARE_PORTAL, { portalId: portal.id, kitId: kit.kitId });
    } else {
      showModal(ModalTypes.SHARE_KIT, { kitId: kit.kitId });
    }
  }, [showModal, portal, kit.kitId]);

  useOpenKitModalFromUrl(kit, location.search, showModal);

  const getItemUrl = useCallback(
    (item: Item) => {
      return buildItemUrl(item, {
        space,
        portal,
        section: displaySection,
      });
    },
    [displaySection, portal, space]
  );

  const shouldRenderInsertPanel =
    !configureMode && pageContentType === PageContentTypes.section && canEditContent;

  // MARK : Rendering
  /* -------------------------------------------------------------------------------*/

  function renderKitNav() {
    return (
      <SubNavContainer>
        <KitNav
          space={space}
          portal={portal}
          kit={kit}
          kitVersion={kitVersion}
          sectionId={displaySection?.id}
        />
      </SubNavContainer>
    );
  }

  function renderInsertPanel() {
    if (!shouldRenderInsertPanel) return;
    return <InsertPanel activeBanner={activeSpaceAlert} superNavVisible={!configureMode} />;
  }

  function renderSection() {
    return (
      <SectionComponent
        section={displaySection}
        kit={kit}
        kitVersion={kitVersion}
        onSelectItem={onSelectItem}
        setInspectorState={setInspectorState}
        selectedItems={selectedItems}
        scrollContainerRef={scrollContainerRef}
        canEditContent={canEditContent}
        getItemUrl={getItemUrl}
      />
    );
  }

  function renderEmpty() {
    return canEditContent ? (
      <EmptyState
        title="Add a Section"
        subtitle="Sections keep things organized (e.g. Logos, Colors, Icons)."
        iconProps={{ iconId: "plus", size: "60", fill: "gray" }}
        extra={<Button text="Add Section" onClick={openNewSectionModal} mt="l" />}
      />
    ) : (
      <EmptyState
        title="This kit is empty"
        subtitle="No assets have been added yet."
        iconProps={{
          iconId: "empty-state.assets",
          size: "90",
          fill: "gray",
        }}
      />
    );
  }

  function renderTrashedItems() {
    return (
      <KitRecentlyDeletedItems
        kit={kit}
        kitVersion={kitVersion}
        getItemUrl={getItemUrl}
        onSelectItem={onSelectItem}
        setInspectorState={setInspectorState}
        selectedItems={selectedItems}
        scrollContainerRef={scrollContainerRef}
        canEditContent={canEditContent}
      />
    );
  }

  function renderRecoveredAssets() {
    return (
      <KitRecoveredAssets
        kit={kit}
        kitVersion={kitVersion}
        getItemUrl={getItemUrl}
        onSelectItem={onSelectItem}
        setInspectorState={setInspectorState}
        selectedItems={selectedItems}
        scrollContainerRef={scrollContainerRef}
        canEditContent={canEditContent}
      />
    );
  }

  function renderGallery() {
    // If we are rendering the gallery behind an item detail
    // The gallery will be stored in navPoint.gallery
    // Otherwise, we are looking at the gallery itself.
    const galleryItem = navPoint.gallery ?? navPoint.item;
    if (galleryItem.data?.viewId) {
      return (
        <GalleryComponent
          section={displaySection}
          item={galleryItem}
          kit={kit}
          kitVersion={kitVersion}
          onSelectItem={onSelectItem}
          setInspectorState={setInspectorState}
          selectedItems={selectedItems}
          scrollContainerRef={scrollContainerRef}
          canEditContent={canEditContent}
          getItemUrl={getItemUrl}
        />
      );
    }
    return (
      <ManualGalleryComponent
        section={displaySection}
        item={galleryItem}
        kit={kit}
        kitVersion={kitVersion}
        onSelectItem={onSelectItem}
        setInspectorState={setInspectorState}
        selectedItems={selectedItems}
        scrollContainerRef={scrollContainerRef}
        canEditContent={canEditContent}
        getItemUrl={getItemUrl}
      />
    );
  }

  function renderContent() {
    function content() {
      switch (pageContentType) {
        case PageContentTypes.empty:
          return renderEmpty();
        case PageContentTypes.section:
          return renderSection();
        case PageContentTypes.trashedItems:
          return renderTrashedItems();
        case PageContentTypes.recoveredAssets:
          return renderRecoveredAssets();
        case PageContentTypes.gallery:
          return renderGallery();
      }
    }
    return (
      <ContentContainer
        ref={scrollContainerRef}
        poweredBy={kit.access.isPublic}
        disableTextSelection={disableTextSelection}
        styleOverrides={{ pt: "xl" }}>
        <Helmet title={kit.name} />
        {content()}
      </ContentContainer>
    );
  }

  function renderKitPage() {
    return (
      <>
        <KitTitle
          space={space}
          portal={portal}
          kit={kit}
          kitVersion={kitVersion}
          pageContentType={pageContentType}
          selectedItems={selectedItems}
          clearSelection={clearInspector}
        />
        <Flex data-test="kit-content-wrapper" overflow="auto" height="100%">
          <TabletUp>{renderKitNav()}</TabletUp>
          {renderInsertPanel()}
          <ScrollContainer
            data-testid="scrollContainer"
            data-inspector-clear="true"
            pl={shouldRenderInsertPanel && "24px"}
            variations={{ "mq.s": { pl: 0 } }}>
            <InspectorView
              source={InspectorSource.kit}
              inspectables={inspectables}
              canEdit={!configureMode && canEditContent}>
              <TabletDown>
                <MobileSubNav
                  button={
                    kit?.access?.permissions?.includes("change_own_status") ? (
                      <Button text="Share Kit" onClick={openShareModal} />
                    ) : undefined
                  }
                />
              </TabletDown>
              {renderContent()}
            </InspectorView>
          </ScrollContainer>
        </Flex>
      </>
    );
  }
  if (hasPortalsFeature && configureMode) {
    return (
      <Flex height="100%">
        <OverflowWrapperPreview>
          <ThemePreviewContentWrapper>{renderKitPage()}</ThemePreviewContentWrapper>
        </OverflowWrapperPreview>
        <ThemeEditorSidePanel>
          <KitThemeControlPanel kit={kit} />
        </ThemeEditorSidePanel>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" width="100%" height="100%" overflow="hidden">
      {renderKitPage()}
    </Flex>
  );
};

export default KitComponent;

// MARK : Hooks
// -------------------------------------------------------------------------------
// Extracts a few hooks used in the Kit component

function useOpenKitModalFromUrl(
  kit: Kit,
  search: string,
  showModal: ReturnType<typeof useShowModal>["showModal"]
) {
  // Open modal from URL
  const modals = useGetModals();
  useEffect(() => {
    if (modals.length) return;

    const modalName = () => {
      const qs = search;
      if (qs.includes("modal=")) return qs.split("modal=")[1].split("&")[0];
      if (qs.includes("invite=1")) return "share";
      return null;
    };

    switch (modalName()) {
      case "manageShareLinks":
      case "share":
        if (kit.access.permissions.includes("change_member_role")) {
          showModal(ModalTypes.SHARE_KIT, { kitId: kit.kitId });
        }
        break;
      case "release":
        if (kit.access.permissions.includes("manage")) {
          showModal(ModalTypes.CREATE_KIT_VERSION, {
            kitId: kit.kitId,
            spaceId: kit.spaceId,
          });
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
