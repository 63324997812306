import {
  API,
  Item,
  Asset,
  ContentType,
  camelize,
  AnyObject,
  PortalItem,
} from "@thenounproject/lingo-core";
import { createQueryAction } from "./actionCreators";

type Args = {
  itemType?: Item["type"] | PortalItem["type"];
  assetType?: Asset["type"];
};

const [useContentTypes, fetchContentTypes] = createQueryAction<Args, ContentType[], ContentType[]>(
  {
    entity: "kits",
    action: "fetchContentTypes",
    denormalize: false,
  },
  async ({ args: { itemType, assetType } }) => {
    const res = await API.call<{ content_types: AnyObject }>({
      endpoint: `meta/content_types`,
      method: "GET",
      query: {
        item_type: itemType,
        type: assetType,
      },
    });
    // content_types is a map of { type : ContentType }
    const contentTypes = camelize(res.result.content_types);
    return { result: Object.values(contentTypes), entities: {} };
  }
);

export default useContentTypes;
export { fetchContentTypes };
