/* eslint complexity: 0 */

/**
 * Kit reducer
 */

import _merge from "lodash/merge";
import _mergeWith from "lodash/mergeWith";
import _union from "lodash/union";
import remove from "lodash/remove";
import { Kit } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";

import { updateKitUserVersion } from "@redux/actions/kits/useUpdateKitUserVersion";
import { fetchKitOutline } from "@redux/actions/kitVersions/useKitOutline";
import { updateKitFavorite } from "@redux/actions/kits/useUpdateKitFavorite";
import { fetchKits } from "@redux/actions/kits/useKits";
import { deleteKit } from "@redux/actions/kits/useDeleteKit";

const getKitsFromResponse = action => {
  let kits;
  try {
    kits = action?.response?.entities?.kits || action?.payload?.entities?.kits;
  } catch {
    // That's ok
  }
  return kits;
};

const defaultKitReducer = (state, action) => {
  const kits = getKitsFromResponse(action);
  if (kits) {
    _mergeWith(state, kits, (objValue, srcValue, key) => {
      if (key === "theme") {
        return srcValue ?? objValue;
      }
    });
  }
  return state;
};

function getKitUuid(id: string, state: Record<string, Kit>): string {
  if (state[id]) return id;
  return (Object.values(state).find(k => k.shortId === id) || {}).kitId;
}

export default createEntityReducer<Kit>(
  "kits",
  queryBuilder => {
    queryBuilder
      // TOOD: when a kit is edited, we need to reorder the space kits
      .addCase(deleteKit.fulfilled, (state, action) => {
        // Delete kit actuall returns the draft that was deleted
        const deletedDraft = action.payload.entities.kitVersions[action.payload.result];
        const { spaceId } = deletedDraft;
        fetchKits.getQueryData(state, { spaceId }).forEach(query => {
          if (!query.data?.kits) return;
          const removed = remove(query.data.kits, id => id === deletedDraft.kitId);
          query.data.total -= removed.length;
        });
      });
  },
  objectBuilder => {
    objectBuilder
      .addCase(deleteKit.fulfilled, (state, action) => {
        // Delete kit actually returns the draft
        // See note in useDeleteKit
        const deletedDraft = action.payload.entities.kitVersions[action.payload.result];
        state[deletedDraft.kitId].status = "deleted";
      })
      .addCase(fetchKitOutline.rejected, (state, action) => {
        const kitId = getKitUuid(action.meta.arg.args.kitId, state),
          kit = state[kitId],
          version = action.meta.arg.args.version;

        if (!kit || !kit.access) return state;
        if (kit.access.useVersion === null) return state;
        if (kit.access.useVersion === version) {
          _merge(state, {
            [kitId]: { access: { useVersion: null } },
          });
        }
      })
      .addCase(updateKitFavorite.fulfilled, (state, action) => {
        // Optimisic update
        const { kitId, isFavorite } = action.meta.arg;
        return _merge(state, {
          [kitId]: { access: { isFavorite } },
        });
      })
      .addCase(updateKitFavorite.rejected, (state, action) => {
        // Optimisic update revert
        const { kitId, isFavorite } = action.meta.arg;
        return _merge(state, {
          [kitId]: { access: { isFavorite: !isFavorite } },
        });
      })
      .addCase(updateKitUserVersion.fulfilled, (state, action) => {
        return _merge(state, {
          [action.meta.arg.kitId]: { access: { useVersion: action.meta.arg.version } },
        });
      })
      .addDefaultCase((state, action) => {
        return defaultKitReducer(state, action);
      });
  }
);
