/*
 * Entities are objects that are represented in the database, are fetched through the API, and
 * represented in the state in a normalized (flat) structure. API middleware handles normalization
 * before *_SUCCESS actions are called.
 */

import { combineReducers } from "redux";

import apiTokens from "./apiTokens";
import assets from "./assets";
import contentTypes from "./contentTypes";
import downloadRequests from "./downloadRequests";
import fields from "./fields";
import invitations from "./invitations";
import items from "./items";
import kitCollectionItems from "./kitCollectionItems";
import kitCollections from "./kitCollections";
import kitMembers from "./kitMembers";
import portals from "./portals";
import portalMembers from "./portalMembers";
import portalItems from "./portalItems";
import kits from "./kits";
import kitVersions from "./kitVersions";
import sections from "./sections";
import shortIds from "./shortIds";
import spaceMembers from "./spaceMembers";
import spaces from "./spaces";
import tags from "./tags";
import users from "./users";
import views from "./views";
import analytics from "./analytics";
import search from "./search";
import directLinks from "./directLinks";

export default combineReducers({
  apiTokens,
  assets,
  contentTypes,
  downloadRequests,
  fields,
  invitations,
  items,
  kitCollections,
  kitCollectionItems,
  kitMembers,
  portals,
  portalMembers,
  portalItems,
  kits,
  kitVersions,
  search,
  sections,
  shortIds,
  spaceMembers,
  spaces,
  tags,
  users,
  views,
  analytics,
  directLinks,
});
