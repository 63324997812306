import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  assetId: string;
  name: string;
};

const [useCreateDirectLink, createDirectLink] = createAsyncAction(
  "assets/createDirectLink",
  async ({ assetId, name }: Args) => {
    return await API.call<number>({
      endpoint: `assets/${assetId}/direct_links`,
      method: "POST",
      entity: API.Entity.directLink,
      data: {
        name,
      },
    });
  }
);
export default useCreateDirectLink;
export { createDirectLink };
