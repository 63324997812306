import { fetchAsset } from "@redux/actions/assets/useAsset";
import { useAppDispatchV1, useAppSelectorV1 } from "@redux/hooks";
import { useRef, useEffect, useCallback } from "react";
import { APIResponse, Asset } from "@thenounproject/lingo-core";
import { ASSET_POLL_DELAY } from "@redux/middleware/polling";

export function usePollAsset(asset: Asset) {
  const dispatch = useAppDispatchV1();
  const isDoneProcessing = useRef(false);
  const isIntersecting = useRef(false); // Track visibility status
  const { id } = asset;

  const assets = useAppSelectorV1(state => state.entities.assets.objects);
  const storedAsset = assets?.[asset.id];
  const assetComplete = storedAsset?.meta?.assetProcessing === "complete";
  const observeElement = useCallback(
    (element: HTMLElement | null) => {
      if (!element || assetComplete) return;
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          isIntersecting.current = entry.isIntersecting;
          if (entry.isIntersecting && !isDoneProcessing.current) {
            setTimeout(async () => {
              if (!isIntersecting.current) return;
              const res = await dispatch(fetchAsset({ args: { assetId: id } }));
              const payload = res.payload as APIResponse;

              if (payload?.entities?.assets[id]?.meta?.assetProcessing === "complete") {
                isDoneProcessing.current = true;
                observer.disconnect();
              }
            }, ASSET_POLL_DELAY);
          }
        });
      });

      observer.observe(element);

      return () => observer.disconnect();
    },
    [assetComplete, dispatch, id]
  );

  useEffect(() => {
    const element = document.querySelector(`[data-assetid="${id}"]`) as HTMLElement;
    observeElement(element);

    return () => {
      isDoneProcessing.current = false;
    };
  }, [id, observeElement]);
}
