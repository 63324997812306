import React from "react";
import { Box, Text, BoxProps, Button } from "@thenounproject/lingo-core";
import useShowModal from "@redux/actions/useModals";

type Props = {
  title: string;
  message?: string | React.ReactNode;
  children?: React.ReactNode;
  styleOverrides?: BoxProps;
  hideDismiss?: boolean;
};

const ModalHeader: React.FC<Props> = ({
  title,
  message,
  children,
  styleOverrides,
  hideDismiss = false,
}) => {
  const { dismissModal } = useShowModal();
  return (
    <Box
      zIndex={20}
      background="white"
      borderBottom="default"
      as="header"
      width="100%"
      pt="8px"
      display="block"
      {...styleOverrides}>
      <Box
        mx="auto"
        textAlign="center"
        py="16px"
        px={"80px"}
        variations={{ "mq.s": { px: "16px" } }}>
        <Text as="h1" font="ui.title">
          {title}
        </Text>
        {message ? <Text mt="4px">{message}</Text> : null}
      </Box>
      {hideDismiss !== true && (
        <Button
          buttonStyle="tertiary"
          aria-label="Close"
          icon="action.close"
          onClick={dismissModal}
          width="36px"
          height="36px"
          themeOverrides={{ primaryColor: "black" }}
          position="absolute"
          top={0}
          right={0}
          zIndex={50}
        />
      )}
      {children}
    </Box>
  );
};

export default ModalHeader;
