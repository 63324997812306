import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  kitCollectionId: string;
  displayOrder: string;
};

const [useReorderKitCollectionItems, reorderKitCollectionItems] = createAsyncAction(
  "kitCollections/reorderItems",
  async ({ kitId, kitCollectionId, displayOrder }: Args, thunkApi) => {
    const state = thunkApi.getState();

    const collectionKitIds = state.entities.kitCollections.objects[kitCollectionId].items
      .map(id => state.entities.kitCollectionItems.objects[id])
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(k => k.kit);
    const ids = collectionKitIds.filter((k: string) => k !== kitId);
    const [direction, dropId] = displayOrder.split(":");
    const dropIndex = ids.indexOf(dropId);
    const spliceIndex = dropIndex + (direction === "before" ? 0 : 1);
    ids.splice(spliceIndex, 0, kitId);

    const items = ids.map((id: string, index: number) => {
      return {
        kit_uuid: id,
        display_order: index * 10,
      };
    });
    return await API.call<string>({
      endpoint: `kit_collections/${kitCollectionId}/kits`,
      method: "PUT",
      data: { items },
      entity: API.Entity.kitCollectionItem,
    });
  }
);

export default useReorderKitCollectionItems;
export { reorderKitCollectionItems };
