import React from "react";
import styled from "styled-components";
import {
  Box,
  Flex,
  Text,
  ImageView,
  Icon,
  Button,
  PopupMenu,
  PrivacyLevel,
  useBoolean,
  Kit,
  useSpaceThemeName,
  SpaceThemeNames,
  utils,
} from "@thenounproject/lingo-core";

const iconMap = {
  [PrivacyLevel.public]: "info.globe",
  [PrivacyLevel.private]: "navigation.user-group",
  [PrivacyLevel.password]: "info.globe-lock",
};

type Props = {
  id: string;
  kit: Kit;
  identifier?: string;
  onClick: any;
  setFavorited: any;
  menuItems: JSX.Element;
  showKitDetails?: boolean;
};

const { getColor } = utils;

const KitCardWrapper = styled(Box)<{ showOverlay?: boolean }>`
  cursor: pointer;
  background-color: ${getColor("white")};
  transition: background-color 0.3s ease;

  ${({ theme }) =>
    theme.themeName === SpaceThemeNames.wyman &&
    `:hover {
    background-color: ${getColor("grayLighter")}
    }`}
  .hover {
    cursor: pointer;
    opacity: ${props => (props.showOverlay ? "1" : "0")};
    background: linear-gradient(
      180deg,
      rgba(42, 42, 42, 0) 0%,
      rgba(42, 42, 42, 0.45) 42.71%,
      rgba(42, 42, 42, 0.9) 95.83%
    );
    transition: opacity 0.3s ease;
  }

  &:hover {
    .hover {
      opacity: 1;
    }
  }
`;

const KitCardCompact: React.FC<Props> = ({
  id,
  kit,
  onClick,
  identifier,
  setFavorited,
  menuItems,
  showKitDetails,
}) => {
  const themeName = useSpaceThemeName();
  const [menuOpen, setMenuOpen, setMenuClosed] = useBoolean(false);

  const permissions = kit.access.permissions || [],
    canFavorite = setFavorited && permissions.includes("favorite"),
    isFavorited = kit.access.isFavorite;

  /**
   * Since a single kit can be in multiple collections all in view at the same time,
   * we need to provide an additional identifier on top of the kit ID - in this case the collection id
   */
  const menuId = `${kit.kitId}-${identifier}-overflow-menu-compact`;

  const popupProps = {
    source: menuId,
    close: setMenuClosed,
    open: menuOpen,
    vPos: undefined,
    hPos: undefined,
    width: 220,
    maxHeight: undefined,
    stayOpenOnClick: false,
    clickAwayClose: true,
    "data-testid": menuId,
  };

  const menu = menuOpen && menuItems ? <PopupMenu {...popupProps}>{menuItems}</PopupMenu> : null;

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClick(kit);
  };

  const buttonThemeVals = {
    [SpaceThemeNames.classic]: { themeOverrides: { primaryColor: "black" } },
    [SpaceThemeNames.scher]: { themeOverrides: { primaryColor: "white" } },
    [SpaceThemeNames.wyman]: {
      themeOverrides: { primaryColor: "black" },
    },
  };

  const buttonOverrides = buttonThemeVals[themeName];

  function renderFavoriteButton() {
    return (
      <Button
        id="favorite-button"
        buttonStyle="tertiary"
        icon={isFavorited ? "info.star-filled" : "info.star"}
        m="none"
        ml="6px"
        p="0"
        size="small"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setFavorited(kit, !isFavorited);
        }}
        {...buttonOverrides}
      />
    );
  }

  function renderOverflowButton() {
    return (
      <Button
        id="overflow-button"
        data-popup-source={menuId}
        buttonStyle="tertiary"
        icon="overflow"
        p="0"
        ml="6px"
        size="small"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setMenuOpen();
        }}
        {...buttonOverrides}
      />
    );
  }

  const normalKitCardThemeVals = {
    [SpaceThemeNames.classic]: {
      contentContainerOverrides: {
        mt: "s",
      },
      imageViewOverrides: {
        borderRadius: "small",
      },
    },
    [SpaceThemeNames.wyman]: {
      kitCardWrapperOverrides: {
        border: "default",
        borderRadius: "large",
        p: showKitDetails === false ? "" : "s",
      },
      imageViewOverrides: {
        borderRadius: "medium",
      },
    },
  };

  function renderNormalKitCard() {
    const { kitCardWrapperOverrides, imageViewOverrides } = normalKitCardThemeVals[themeName];
    return (
      <KitCardWrapper
        data-testid={id}
        width="100%"
        onClick={handleClick}
        {...kitCardWrapperOverrides}>
        <ImageView
          css={{ cursor: onClick ? "pointer" : null }}
          src={kit.images.cover as string}
          icon="content.kit"
          alt={kit.name}
          aspectRatio={61}
          cover
          background="grayLight"
          border="default"
          {...imageViewOverrides}
        />
        {showKitDetails !== false && (
          <Flex width="100%" mt="s" justifyContent="space-between" borderRadius="0 0 4px 4px">
            <Text as="h1" id="kit-title" font="ui.small" truncate onClick={handleClick}>
              {kit.name}
            </Text>
            <Flex alignItems="center">
              <Icon id={`privacy-${kit.privacy}`} iconId={iconMap[kit.privacy]} size="16" />
              {canFavorite && renderFavoriteButton()}
              {menuItems && renderOverflowButton()}
              {menu}
            </Flex>
          </Flex>
        )}
      </KitCardWrapper>
    );
  }

  function renderOverlayTypeKitCard() {
    return (
      <KitCardWrapper data-testid={id} width="100%" showOverlay={menuOpen} onClick={handleClick}>
        <ImageView
          css={{ cursor: onClick ? "pointer" : null }}
          src={kit.images.cover as string}
          icon="content.kit"
          alt={kit.name}
          aspectRatio={64}
          cover
          background="grayLight"
          border="default"
        />
        <Flex
          className="hover"
          width="100%"
          pt="l"
          px="m"
          pb="s"
          position="absolute"
          bottom="0"
          textAlign="left">
          <Flex width="100%" mt="s" justifyContent="space-between" borderRadius="0 0 4px 4px">
            <Text
              as="h1"
              id="kit-title"
              font="ui.small"
              color="white"
              truncate
              onClick={handleClick}>
              {kit.name}
            </Text>
            <Flex alignItems="center">
              <Icon
                id={`privacy-${kit.privacy}`}
                iconId={iconMap[kit.privacy]}
                size="16"
                fill="white"
              />
              {canFavorite && renderFavoriteButton()}
              {menuItems && renderOverflowButton()}
              {menu}
            </Flex>
          </Flex>
        </Flex>
      </KitCardWrapper>
    );
  }

  switch (themeName) {
    case SpaceThemeNames.classic:
    case SpaceThemeNames.wyman: {
      return renderNormalKitCard();
    }
    case SpaceThemeNames.scher: {
      return renderOverlayTypeKitCard();
    }
  }
};

export default KitCardCompact;
