import { useCallback, useEffect, useMemo, useRef } from "react";
import _merge from "lodash/merge";
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import { useAppDispatchV1, useAppSelectorV1 } from "@redux/hooks";
import { Action, AsyncThunk, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { type AppDispatch, type RootState } from "@redux/store";
import {
  APIResponse,
  isError,
  LingoError,
  usePrevious,
  type AnyObject,
} from "@thenounproject/lingo-core";
import { GetState } from "@redux/store/reduxStore";

import { captureException } from "../../../adapters/sentry";

export const DEFAULT_PAGE_SIZE = 100;

// The type of the hook for calling the action in a component
type QueryHook<Args, Result, Options> = (args: Args, options?: Options) => QueryState<Result>;

// The resturn type of the hook
type QueryState<Result> = {
  data?: Result;
  fetchNextPage: () => void;
  refresh: () => void;
  isLoading: boolean;
  status: "pending" | "fulfilled" | "rejected";
  error: LingoError;
};

type PagingData = {
  page: number;
};

type ActionMeta = {
  queryKey: string;
  entity: Entity;
  action: string;
  resultSource: "fetched" | "prefetched";
};
// The internal action config type, this includes the meta types that are used by the reducer. The external payloadCreator should not include these.
type ActionConfig = {
  state: RootState;
  serializedErrorType: LingoError;
  fulfilledMeta: ActionMeta;
  rejectedMeta: ActionMeta;
  rejectValue: LingoError;
  dispatch: AppDispatch;
};

// The action creator type that the external payloadCreator should match
type QueryActionPayloadCreator<R, P> = (
  arg: P,
  api: { getState: () => RootState; dispatch: AppDispatch }
) => Promise<APIResponse<R>>;

// The type of the data that is stored in the store
export type QueryData<Args, Result = unknown> = {
  requestStatus: "pending" | "fulfilled" | "rejected";
  requestId: string;
  queryKey: string;
  arg: {
    args: Args;
    paging: PagingData;
  };
  data?: Result;
  error?: LingoError;
  startedTimeStamp?: number;
  dateFullfilled?: number;
} & Omit<Config<Args, Result, unknown>, "condition" | "cacheKey" | "prepareData" | "prefetchData">;

// This is a quick hack to improve the migration process.
// Only entities that have migrated reducers will be supported.
// Eventually we can remove this and just do `keyof RootState["entities"]`
type SupportedEntities<T> = {
  [K in keyof T]: T[K] extends { queries: unknown; objects: unknown } ? K : never;
}[keyof T];

// type ResultTypes = RootState["entities"][Entity]["objects"];

// Basic info about the query
type Entity = SupportedEntities<RootState["entities"]>;
type Config<Args, Result, ActionResult> = {
  /**
   * The entity that the query is for. This must be the key in the entities object.
   */
  entity: Entity;
  /**
   * The type of query being performed on the entity.
   *
   * ### Common actions
   * * `query`: fetching list of the entity
   * * `fetch`: fetching a single entity
   *
   * Variations of these can be used for specialized queries and fetches.
   */
  action: string;
  /// Return false to skip the action
  condition?: (arg: { args: Args }, api: { getState: () => RootState }) => boolean;
  /**
   * A map of key and entitiy to populate normalized (denormalize) data from the store
   * By default, the the query result is assumed to be the id or ids of `config.entity` and will be denormalized from the objects of that entity.
   *
   * If set to false, the data will not be denormalized.
   *
   * If an object is provided, it can map keys to entities to denormalize. Nested objects can be represented using the `entity` key along side nested object keys.
   *
   * Examples:
   * ```
   * // For an item
   * { asset: "assets" }
   *
   * // For a section with items to populate items and items[0].asset
   * {
   *    items: {
   *       entity: "items",
   *       asset: "assets"
   *    }
   * }
   * ```
   */
  denormalize?: Schema | false;
  pagingKey?: string;
  cacheKey?: (args: Args) => AnyObject;
  debounce?: boolean;
  prepareData?: (data: Result) => Result;
  prefetchData?: (
    arg: { args: Args },
    api: { getState: () => RootState }
  ) => APIResponse<ActionResult>;
};

type Options = {
  skip?: boolean;
  refetchOnMount?: boolean;
  debounce?: boolean;
};

function _getQueryKey(prefix: string, args: AnyObject) {
  const replacer = (key: string, value: unknown) =>
    value instanceof Object && !(value instanceof Array)
      ? Object.keys(value)
          .sort()
          .reduce((sorted, key) => {
            sorted[key] = value[key];
            return sorted;
          }, {})
      : value;

  return `${prefix}(${JSON.stringify(args, replacer)})`;
}

type ActionType<Args, Result, ActionResult> = AsyncThunk<
  APIResponse<ActionResult>,
  { args: Args; paging?: PagingData; options?: { prefetch: boolean } },
  ActionConfig
> & {
  getQueryKey: (args: Args) => string;
  getQueryData: (
    state: Record<string, QueryData<unknown, unknown>>,
    args: Partial<Args>
  ) => QueryData<Args, ActionResult>[];
  lazy: (
    store: { dispatch: AppDispatch; getState: GetState },
    args: Args,
    options?: { persistError: boolean }
  ) => Promise<Result>;
  invalidateAction: (args: Partial<Args>) => Action;
};

export default function createQueryAction<Args, Result, ActionResult = unknown>(
  config: Config<Args, Result, ActionResult>,
  payloadCreator: QueryActionPayloadCreator<ActionResult, { args: Args; paging: PagingData }>
): [QueryHook<Args, Result, Options>, ActionType<Args, Result, ActionResult>] {
  // Helpers
  const typePrefix = [config.entity, config.action].join("/");
  const getCacheKey = (args: Args) => {
    const _args = config.cacheKey ? config.cacheKey(_cloneDeep(args)) : args;
    return _getQueryKey(typePrefix, _args);
  };

  const getCachedData = (state: RootState, args: Args) => {
    return state.entities[config.entity].queries[getCacheKey(args)] as QueryData<
      Args,
      ActionResult
    >;
  };

  // We need to remove any non-serializable data from the config to be passed into the action
  const serializableConfig = { ...config };
  serializableConfig.condition = undefined;
  serializableConfig.cacheKey = undefined;
  serializableConfig.prepareData = undefined;
  serializableConfig.prefetchData = undefined;

  const action = createAsyncThunk<
    APIResponse<ActionResult>,
    { args: Args; paging: PagingData; options?: { prefetch: boolean } },
    ActionConfig
  >(
    typePrefix,
    async (args, thunkApi) => {
      // Wrap the payloadCreator so we can call fulfill
      const queryKey = getCacheKey(args.args);
      let resultSource: "fetched" | "prefetched" = "fetched";
      try {
        let res: APIResponse<ActionResult>;
        if (args.options?.prefetch) {
          res = config.prefetchData?.(args, thunkApi);
        }
        if (res) {
          resultSource = "prefetched";
        } else {
          res = await payloadCreator(args, thunkApi);
        }
        return thunkApi.fulfillWithValue(res, { queryKey, resultSource, ...serializableConfig });
      } catch (err) {
        if (isError(err)) {
          return thunkApi.rejectWithValue(err, { queryKey, resultSource, ...serializableConfig });
        } else {
          captureException(err);
          throw err;
        }
      }
    },
    {
      serializeError: error => error as LingoError,
      condition: config.condition,
      getPendingMeta: ({ arg }) => {
        return {
          queryKey: getCacheKey(arg.args),
          ...serializableConfig,
        };
      },
    }
  ) as ActionType<Args, Result, ActionResult>;

  action.getQueryKey = (args: Args) => {
    return getCacheKey(args);
  };

  action.getQueryData = (
    state: Record<string, QueryData<unknown, unknown>>,
    args: Partial<Args>
  ): QueryData<Args, ActionResult>[] => {
    return Object.values(state).filter(q => {
      // IDEA: should we exclude invalidated data here?
      return (
        config.entity === q.entity && config.action === q.action && partialMatch(q.arg.args, args)
      );
    }) as QueryData<Args, ActionResult>[];
  };

  action.invalidateAction = (args: Partial<Args>) => {
    return invalidateQueryAction({
      entity: config.entity,
      action: config.action,
      args,
    });
  };

  function generateQueryData(
    args: Args,
    cachedData: QueryData<Args, ActionResult>,
    skip: boolean
  ): QueryData<Args, ActionResult> | null {
    return _merge(
      {},
      {
        requestStatus: skip ? "fulfilled" : "pending",
        endpointName: typePrefix,
        requestId: undefined,
        arg: {
          args,
          // If the first request fails, paging data may not have been inserted into the store.
          // Merging here ensures that this default page 1 is in the queryData.
          paging: {
            page: 1,
          },
        },
        data: null,
        error: null,
        startedTimeStamp: Date.now(),
        dateFullfilled: 0,
      },
      cachedData
    ) as QueryData<Args, ActionResult>;
  }

  action.lazy = async (
    { dispatch, getState },
    args: Args,
    options: { persistError?: boolean } = {}
  ) => {
    const cachedData = getCachedData(getState(), args);
    const queryData = generateQueryData(args, cachedData, false);

    if (options.persistError && queryData.error) {
      throw queryData.error;
    }

    // -1 date fullfilled means the data was invalidated
    let data = queryData.dateFullfilled >= 0 ? queryData.data : null;
    if (!data) {
      const res = await dispatch(
        action({
          args,
          paging: { page: 1 },
          options: { prefetch: queryData.dateFullfilled >= 0 },
        })
      );
      if (action.fulfilled.match(res)) {
        data = res.payload.result;
      } else if (action.rejected.match(res)) {
        throw res.payload;
      }
    }
    return prepareData<Result>(getState().entities, data, config);
  };

  const useAction = (args: Args, options: Options = {}) => {
    const _dispatch = useAppDispatchV1();
    const hasFetched = useRef(false);
    const dispatch = useMemo(
      () =>
        config.debounce || options.debounce
          ? (_debounce(_dispatch, 250, { leading: true }) as unknown as typeof _dispatch)
          : _dispatch,
      [_dispatch, options.debounce]
    );

    const argHash = _getQueryKey(typePrefix, args);

    const cachedQueryData = useAppSelectorV1((state: RootState) => getCachedData(state, args));
    const queryData = useMemo(
      () => generateQueryData(args, cachedQueryData, options.skip),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [argHash, cachedQueryData]
    );
    const entities = useAppSelectorV1((state: RootState) => state.entities);
    const data = useMemo(
      () => prepareData<Result>(entities, queryData.data, config),
      [entities, queryData.data]
    );

    const hasData = useMemo(() => {
      if (!data) return false;
      if (Array.isArray(data)) return Boolean(data.length);
      if (config.pagingKey) {
        const arr = _get(data, config.pagingKey, []);
        return Array.isArray(arr) ? arr.length : false;
      }
      return false;
    }, [data]);

    const fetchFirstPage = useCallback(async () => {
      hasFetched.current = true;
      await dispatch(
        action({
          args,
          paging: { page: 1 },
          options: { prefetch: queryData.dateFullfilled >= 0 },
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [argHash, dispatch]);

    const fetchNextPage = useCallback(async () => {
      const paging = { ...queryData.arg.paging, page: queryData.arg.paging.page + 1 };
      // If we don't have any data yet, enforce page 1.
      // This avoids incremenint got page 2 if an erro occured on the first attempt.

      if (!hasData) {
        paging.page = 1;
      }

      await dispatch(
        action({
          args,
          paging,
          options: { prefetch: true },
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [argHash, dispatch, hasData, queryData.arg.paging]);

    // We're using this to compare the args and see if they've changed
    const previousArgHash = usePrevious(argHash);
    const queryArgHash = _getQueryKey(typePrefix, queryData.arg.args);

    useEffect(() => {
      if (options?.skip) {
        return;
      } else if (!hasData && !queryData.requestId) {
        void fetchFirstPage();
      } else if (argHash !== queryArgHash) {
        void fetchFirstPage();
      } else if (options.refetchOnMount && (!hasFetched.current || argHash !== previousArgHash)) {
        void fetchFirstPage();
      } else if (queryData.dateFullfilled < 0) {
        void fetchFirstPage();
      }
    }, [
      argHash,
      fetchFirstPage,
      hasData,
      options.refetchOnMount,
      options?.skip,
      previousArgHash,
      queryArgHash,
      queryData.dateFullfilled,
      queryData.requestId,
    ]);

    return {
      // Returning undefined instead of null allows for defaults when destructuring the hook result.
      // e.g. const { data = [] } = useAction()
      data: data ?? undefined,
      fetchNextPage,
      refresh: fetchFirstPage,
      status: queryData.requestStatus,
      isLoading: queryData.requestStatus === "pending",
      error: queryData.error,
    };
  };

  return [useAction, action];
}

type Schema = {
  entity?: Entity;
  [key: string]: Entity | Schema;
};

// Handles denormalizing data based on config.denormalize
function denormalize(obj, entities: RootState["entities"], schema: Schema | Entity) {
  if (!obj) return obj;
  if (!schema) return obj;
  if (typeof schema === "object") {
    // { data: ["kit-1"]}
    // { data: { entity: kitVersions }}
    const { entity, ...rest } = schema;
    const result = entity ? denormalize(obj, entities, entity) : obj;

    if (Array.isArray(result)) {
      return result.map(v => denormalize(v, entities, rest));
    }
    return Object.keys(rest).reduce((res, key) => {
      return {
        ...res,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        [key]: denormalize(res[key], entities, schema[key]),
      };
    }, result);
  } else if (Array.isArray(obj)) {
    return obj.map(v => denormalize(v, entities, schema));
  } else {
    if (entities[schema] === undefined) {
      // this should never happen in production but it is easy to
      // forget to add the entity to the entities object during testing
      throw new Error(`Entity "${schema}" not found in entities during denorm`);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return entities[schema].objects[obj];
  }
}

function verifyDenormalizeConfig(
  config: Pick<Config<unknown, unknown, unknown>, "entity" | "action" | "denormalize">,
  data: unknown
) {
  const log = (type: string) => {
    console.error(
      `denormalize undefined for action ${config.entity}/${config.action}, but data is of type ${type}. You probably need to set a denormalize config.`,
      data
    );
  };
  // This is most likely a bug, any data that is not a string|number|string[]|number[] or number should have a denormalize config
  if (data && config.denormalize === undefined) {
    if (Array.isArray(data)) {
      if (data.length > 0 && !["string", "number"].includes(typeof data[0])) {
        log(`${typeof data[0]}[]`);
      }
    } else if (!["string", "number"].includes(typeof data)) {
      log(typeof data);
    }
  }
}

export function prepareData<Result>(
  entities: RootState["entities"],
  data,
  config: Pick<
    Config<unknown, Result, unknown>,
    "entity" | "action" | "denormalize" | "prepareData"
  >
) {
  verifyDenormalizeConfig(config, data);

  // Map the data to the actual entities
  const schema = {
    data: {
      entity: config.denormalize === false ? null : config.entity,
      ...(config.denormalize ?? {}),
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access

  const result = denormalize({ data }, entities, schema).data as Result;
  if (config.prepareData) {
    return config.prepareData(result);
  }
  return result;
}

type QueryAction = {
  type: string;
  meta: ActionMeta & QueryData<unknown, unknown>;
  payload?: LingoError | APIResponse;
};

export function isQueryAction(action: unknown, entity: string = null): action is QueryAction {
  const act = action as AnyObject;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return act?.meta && act.meta.queryKey && (entity === null || act.meta.entity === entity);
}

function partialMatch(obj: AnyObject, matches: AnyObject) {
  if (!matches) return true;
  return Object.keys(matches).every(key => {
    if (!obj) return false;
    if (Array.isArray(matches[key])) {
      return _isEqual(obj[key], matches[key]);
    } else if (typeof matches[key] === "object") {
      if (typeof obj[key] !== "object") return false;
      return partialMatch(obj[key], matches[key]);
    }
    return obj[key] === matches[key];
  });
}

// MARK : Query Invalidation
// -------------------------------------------------------------------------------
// To invalidate a query you can call
// ```ts
//  const invalidationAction = actionType.invalidateAction({...args})
//  dispatch(invalidationAction)
// ```
// Any existing queries that partially match the args will be invalidated.
// Any active hooks for the query will be immediately re-run
// If ther are not hooks registered when the query is invalidted, this will still
// trigger a refetch when another hook is created.

//

export const invalidateQueryAction = createAction<{
  entity: Entity;
  action: string;
  args: AnyObject;
}>(`query/invalidate`);

export function invalidateQuery(query: QueryData<unknown, unknown>) {
  _merge(query, {
    startedTimeStamp: Date.now(),
    // -1 will force the query to refetch the first page without clearing the existing data
    dateFullfilled: -1,
  });
}

export function handleInvalidationAction(
  state: Record<string, QueryData<unknown, unknown>>,
  action: ReturnType<typeof invalidateQueryAction>
) {
  const { entity, action: queryAction, args } = action.payload;
  const queries = Object.values(state).filter(q => {
    return entity === q.entity && queryAction === q.action && partialMatch(q.arg.args, args);
  }) as QueryData<unknown>[];
  queries.forEach(query => invalidateQuery(query));
}
