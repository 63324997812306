/**
 * Renders grid of asset thumbnails for a section in a kit, with inline notes.
 */
import "highlight.js/styles/github.css";

import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
  DragEvent,
  MutableRefObject,
} from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";

import {
  ItemType,
  AssetType,
  Box,
  Flex,
  GalleryGuide,
  GalleryHeading,
  GalleryNote,
  GalleryItem,
  GalleryCodeSnippet,
  GallerySupportingAsset,
  useContextMenu,
  AnyObject,
  Item,
  Section,
  useNavigation,
} from "@thenounproject/lingo-core";

// Actions
import useNotifications from "@actions/useNotifications";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useCreateItemAsset from "@redux/actions/items/useCreateItemAsset";
import useReplaceAssetFile from "@redux/actions/assets/useReplaceAssetFile";

import GalleryDropZone, { DropZoneWrapper } from "./GalleryDropZone";

import { getVersionedItemId, findItemIndexByVersionedId, getAssetsPerRow } from "@helpers/items";
import KeyboardHandler from "./KeyboardHandler";
import useResizeObserver from "../hooks/useResizeObserver";
import InAssetMultiDownload from "./InAssetMultiDownload";
import InAssetDownload from "./InAssetDownload";
import EmptyState from "../EmptyState";
import GalleryContextMenu, { hasContextMenu } from "@features/context-menus/GalleryContextMenu";
import * as galleryUtils from "@helpers/gallery";
import { insertData as insertDataMap, InsertType } from "../../constants/InsertType";
import { validateLayout } from "@helpers/layoutValidation";
import * as spacingUtils from "@helpers/galleryItemSpacing";
import { validateFigmaLink } from "@helpers/figma";
import InsertAssetMenu from "./InsertAssetMenu";
import { InViewTracker } from "../../contexts/InViewProvider";
import { useKitContext } from "@contexts/KitProvider";
import { Inspectable } from "@constants/Inspector";
import { useAppSelectorV1 } from "@redux/hooks";
import type { RootState } from "@redux/store";
import useBatchSaveItems from "@redux/actions/items/useBatchSaveItems";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";
import { GalleryAssetViewModes } from "@contexts/ViewModeContext";
import GalleryAssetContent from "./GalleryAssetContent/GalleryAssetContent";
import useBatchUpdateItemStatus from "@actions/items/useBatchUpdateItemStatus";
import useUpdateItem from "@redux/actions/items/useUpdateItem";
import { useSelectSpace } from "@redux/selectors/entities/spaces";
import { ItemPolling } from "./GalleryAssetContent/PollingWrappers";

const ItemsWrapper = styled(Flex).attrs({
  flexWrap: "wrap",
  mx: "-8px",
})``;

const AssetThumb = styled(Box).attrs({
  px: "8px",
})``;

const dragImageStyleOverride = `
  height: 32px;
  width: 32px;
  border-radius: 100px;
  background: #cf4a4a;
  position: absolute;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  top: -1000px;
`;

type Props = {
  section?: Section;
  items: Item[];
  item?: Item;
  getItemUrl: (item: Item) => string;
  onSelectItem: (event: React.MouseEvent | KeyboardEvent, itemId: string, items: Item[]) => void;
  setInspectorState: (itemIds: string[]) => void;
  selectedItems: string[];
  canEditContent: boolean;
  scrollContainerRef: MutableRefObject<HTMLDivElement>;
  updateItem?: ReturnType<typeof useUpdateItem>[0];
  batchUpdateItemStatus?: ReturnType<typeof useBatchUpdateItemStatus>[0];
  assetViewMode: GalleryAssetViewModes;
  setDraggingEntity?: ReturnType<typeof useSetDraggingEntity>;
  galleryDraggingActive?: boolean;
};

export const GalleryItems: React.FC<Props> = ({
  section,
  items,
  item,
  getItemUrl,
  onSelectItem,
  setInspectorState,
  selectedItems,
  scrollContainerRef,
  updateItem,
  batchUpdateItemStatus,
  canEditContent,
  galleryDraggingActive,
  setDraggingEntity,
  assetViewMode,
}) => {
  const navigation = useNavigation();
  const location = useLocation();
  const space = useSelectSpace();
  const itemWrapperRef = useRef(null);
  const [editingItem, setEditingItem] = useState(null);
  const [assetMenuData, setAssetMenuData] = useState(null);

  const { kitDisplaySize } = useKitContext();

  const [replaceAssetFile] = useReplaceAssetFile(),
    [createItemAsset] = useCreateItemAsset(),
    { showNotification } = useNotifications(),
    { showModal } = useShowModal();

  const galleryOutline = useMemo(() => galleryUtils.getGalleryOutline(items), [items]);

  const layout = validateLayout(items);

  const isModalOpen = useAppSelectorV1((state: RootState) => Boolean(state.modals.length));
  const [batchSaveItems] = useBatchSaveItems();

  /**
   * When inserting asset items, it's more efficient
   * to watch the layout & save any validation updates
   * as an effect rather than inject the logic into each
   * individual action
   */
  useEffect(() => {
    if (!layout.valid && canEditContent && batchSaveItems && section) {
      void batchSaveItems({ updatedItems: layout.updates });
    }
  }, [layout, canEditContent, batchSaveItems, section, setInspectorState, selectedItems]);

  // Assets per row state mostly exists to trigger a re-render upon window resize
  const [assetsPerRow, setAssetsPerRow] = useState(getAssetsPerRow(null, kitDisplaySize));

  // Assets per row ref exists entirely as an access point for an event listener that will never be refreshed
  const assetsPerRowRef = useRef(getAssetsPerRow(null, kitDisplaySize));
  const onResize = useCallback(
    (node: HTMLDivElement) => {
      const newAssetsPerRow = getAssetsPerRow(node, kitDisplaySize);
      if (assetsPerRowRef.current !== newAssetsPerRow) {
        assetsPerRowRef.current = newAssetsPerRow;
        setAssetsPerRow(newAssetsPerRow);
      }
    },
    [kitDisplaySize]
  );

  useResizeObserver(scrollContainerRef, onResize);

  useEffect(() => {
    setAssetsPerRow(getAssetsPerRow(null, kitDisplaySize));
  }, [kitDisplaySize]);

  // Dragging
  const draggingNodes = useRef(new Set());
  const handleDragOver = useCallback(
    (e: DragEvent) => {
      if (!e.dataTransfer.types || !e.dataTransfer.types.includes("Files")) return;
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
      if (!galleryDraggingActive && setDraggingEntity)
        setDraggingEntity({ entity: DragEntities.GALLERY_ITEM });
    },
    [galleryDraggingActive, setDraggingEntity]
  );

  const handleDragEnter = useCallback(
    (e: DragEvent) => {
      if (!e.dataTransfer.types || !e.dataTransfer.types.includes("Files")) return;
      handleDragOver(e);
      draggingNodes.current.add(e.target);
    },
    [handleDragOver]
  );

  const handleDragLeave = useCallback(
    (e: DragEvent) => {
      if (!e.dataTransfer.types || !e.dataTransfer.types.includes("Files")) return;
      e.preventDefault();
      draggingNodes.current.delete(e.target);
      if (galleryDraggingActive && setDraggingEntity && draggingNodes.current.size === 0) {
        setDraggingEntity({ entity: undefined });
      }
    },
    [galleryDraggingActive, setDraggingEntity]
  );

  const handleDrop = useCallback(
    (e: DragEvent) => {
      if (e.dataTransfer.files.length === 0) return;
      e.preventDefault();
      draggingNodes.current.clear();
      if (galleryDraggingActive && setDraggingEntity) setDraggingEntity({ entity: undefined });
    },
    [galleryDraggingActive, setDraggingEntity]
  );

  const inspectables: Inspectable[] = useMemo(() => {
    return selectedItems.reduce((acc, item) => {
      const id = item.substring(0, item.lastIndexOf("-"));
      const stateItem = items.find(i => i.id === id);
      if (stateItem) {
        acc.push({ item: stateItem, asset: stateItem.asset });
      }
      return acc;
    }, []);
  }, [items, selectedItems]);

  const handleRouteToAsset = useCallback(
    (item: Item) => {
      return navigation.push(getItemUrl(item));
    },
    [getItemUrl, navigation]
  );

  const onTogglePreview = useCallback(
    (item: Item) => {
      if (!item) return;
      handleRouteToAsset(item);
    },
    [handleRouteToAsset]
  );

  const _onStartEditing = useCallback(
    (itemId: string) => {
      setEditingItem(itemId);
      onSelectItem(null, itemId, []);
    },
    [onSelectItem]
  );

  const openInsertAssetMenu = useCallback((e: MouseEvent, insertPosition: InsertPosition) => {
    setAssetMenuData({
      insertPosition,
      coordinates: { xPos: `${e.pageX}px`, yPos: `${e.pageY}px` },
    });
  }, []);

  const closeInsertAssetMenu = useCallback(() => {
    setAssetMenuData(null);
  }, []);

  function renderKeyboardHandler() {
    const selectedItem = selectedItems.length ? selectedItems[selectedItems.length - 1] : null;
    const selectedIndex = selectedItem ? findItemIndexByVersionedId(selectedItem, items) : null;
    return (
      <KeyboardHandler
        items={items}
        selectedIndex={selectedIndex}
        assetsPerRow={assetsPerRow}
        onSelectItem={onSelectItem}
        onTogglePreview={onTogglePreview}
      />
    );
  }

  function renderEmptySection() {
    if (items.length) return null;
    if (!section) return null;
    return canEditContent ? (
      <EmptyState
        title="This section is empty"
        subtitle="Drag &amp; Drop from the Insert Panel to add content.."
        iconProps={{
          iconId: "empty-state.assets",
          size: "90",
          fill: "gray",
        }}
        dropZoneProps={{
          kitId: section.kitId,
          sectionId: section.id,
          startEditingItem: _onStartEditing,
          openInsertAssetMenu,
        }}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      />
    ) : (
      <EmptyState
        title="This section is empty"
        subtitle="You don't have permission to create anything."
        iconProps={{ iconId: "info.lock-open", size: "90", fill: "gray" }}
      />
    );
  }

  const _onFinishEditing = useCallback(
    async (item: Item, data: AnyObject) => {
      if (!updateItem) return;
      if (!data) {
        return setEditingItem(null);
      }
      const deleteIfEmpty = [ItemType.codeSnippet, ItemType.note, ItemType.heading];
      if (deleteIfEmpty.includes(item.type) && data.data?.content === "") {
        // If the text content is removed, delete the item
        const { response, error: responseError } = await batchUpdateItemStatus({
          items: [item.id],
          status: "trashed",
          sectionId: section?.id,
          galleryOutline,
        });

        if (responseError) {
          showNotification({
            message: responseError.message ?? "Failed to delete items.",
            level: "error",
          });
        } else {
          const { result, entities } = response;
          const [itemResult] = result.items;

          if (itemResult.success) {
            const deletedItem = entities.items[itemResult.result];
            const deletedCurrentHash = location.hash
              ? deletedItem.shortId === location.hash.slice(1)
              : false;
            if (deletedCurrentHash) navigation.replace(location.pathname + location.search);
            setInspectorState([]);
          } else {
            showNotification({ message: itemResult.error.message, level: "error" });
          }
        }
      } else {
        const { error: responseError } = await updateItem({ itemId: item.id, updates: data });
        if (responseError)
          showNotification({
            message: responseError.message ?? "Failed to save item content.",
            level: "error",
          });
        else {
          setEditingItem(null);
        }
      }
    },
    [
      updateItem,
      batchUpdateItemStatus,
      section?.id,
      galleryOutline,
      showNotification,
      location.hash,
      location.pathname,
      location.search,
      navigation,
      setInspectorState,
    ]
  );

  const saveItemAsset = useCallback(
    (item, file) => {
      return item.asset
        ? replaceAssetFile({ inspectable: { item, asset: item.asset }, file })
        : createItemAsset({ item, file });
    },
    [replaceAssetFile, createItemAsset]
  );

  // Replacing can also occur for a guide which does
  // not yet have an asset
  const replaceFileAsset = useCallback(
    item => {
      const callback = files => saveItemAsset(item, files[0]);
      showModal(ModalTypes.PICK_FILE, {
        onUploadFiles: callback,
        prompt: "Choose image",
        itemType: item.type,
        assetType: item.asset && item.asset.type,
      });
    },
    [saveItemAsset, showModal]
  );
  /**
   * Listen for paste events and create assets if any
   * applicable data is available on the clipboard.
   *
   * Currently it only listens for Figma links
   */
  useEffect(() => {
    const sectionId = section?.id;
    if (!canEditContent || !sectionId || !items) return;

    function handlePaste(e: ClipboardEvent) {
      /**
       * If the target is an input element, ignore the paste
       */
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        isModalOpen
      )
        return;

      const data = e.clipboardData?.getData("text");
      const isValidFigmaLink = Boolean(validateFigmaLink(data));

      /**
       * If the pasted value doesn't match a Figma link, ignore the paste
       */
      if (!isValidFigmaLink) return;

      const insertPosition = {
        displayOrder: "append",
        insertIndex: items.length ? items.length - 1 : 0,
        kitId: section?.kitId,
        sectionId,
        spaceId: space?.id,
      };
      if (selectedItems.length) {
        insertPosition.displayOrder = `after:${selectedItems[0]}`;
        insertPosition.insertIndex =
          items.findIndex(item => `${item.id}-0` === selectedItems[0]) + 1;
      }

      showModal(ModalTypes.FIGMA_LINK_MODAL, { insertPosition, preloadedState: { url: data } });
      e.preventDefault();
    }
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [
    showModal,
    canEditContent,
    items,
    section?.id,
    section?.kitId,
    selectedItems,
    isModalOpen,
    space?.id,
  ]);

  const {
    contextMenuXPos,
    contextMenuYPos,
    contextMenuOpen,
    handleContextMenuClose,
    setMenuOpen,
    setMenuClosed,
  } = useContextMenu({
    itemRef: itemWrapperRef,
    onOpenMenu: event => {
      let current = event.target as HTMLElement;
      let itemId;

      while (current && !itemId) {
        itemId = current.dataset.itemid;
        current = current.parentElement;
      }
      if (!itemId || !hasContextMenu(inspectables, item)) return setMenuClosed();

      const isSelected = selectedItems.some(i => i === itemId);

      if (!isSelected) {
        onSelectItem(event, itemId, items);
      }
      setMenuOpen();
    },
    autoOpen: false,
  });

  function renderContextMenu() {
    if (!contextMenuOpen || !inspectables.length) {
      return null;
    }
    return (
      <GalleryContextMenu
        position={{ x: contextMenuXPos, y: contextMenuYPos }}
        onCloseMenu={handleContextMenuClose}
        inspectables={inspectables}
        canEdit={canEditContent}
        onEditItem={setEditingItem}
      />
    );
  }

  function renderInsertAssetMenu() {
    if (!assetMenuData) return null;
    return (
      <InsertAssetMenu
        close={closeInsertAssetMenu}
        coordinates={assetMenuData.coordinates}
        insertPosition={assetMenuData.insertPosition}
      />
    );
  }

  function handleAssetClick(e, item) {
    return onSelectItem(e, getVersionedItemId(item), items);
  }

  function renderDropZone(
    item: Item,
    index: number,
    guidePosition?: "left" | "right" | "full",
    nextItemId?: string,
    prevItemId?: string
  ) {
    return canEditContent ? (
      <GalleryDropZone
        itemType={item.type}
        itemDisplayStyle={item?.data.displayStyle}
        itemIndex={index}
        itemId={item.id}
        kitId={item.kitId}
        sectionId={item.sectionId}
        guidePosition={guidePosition}
        nextItemId={nextItemId}
        prevItemId={prevItemId}
        itemWrapperRef={itemWrapperRef}
        startEditingItem={_onStartEditing}
        openInsertAssetMenu={openInsertAssetMenu}
      />
    ) : null;
  }

  const handleReorderDragEnd = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      if (setDraggingEntity) setDraggingEntity({ entity: undefined });
    },
    [setDraggingEntity]
  );

  const handleReorderDragStart = useCallback(
    (e: DragEvent<HTMLElement>) => {
      // ID of item upon which reorder drag is initiated
      e.dataTransfer.effectAllowed = "all";
      // Proxy the selected item state so we can maniplulate it within the callback context
      const versionedDragItemId = e.currentTarget.dataset.dragid;
      if (!versionedDragItemId) {
        e.preventDefault();
        console.warn("Tried to start a reorder, but the drag event couldn't parse an item ID.");
        return;
      }
      let proxiedSelectedItems = _cloneDeep(selectedItems);

      // If the item is not in the selected items, start new selection
      if (!selectedItems.includes(versionedDragItemId)) {
        proxiedSelectedItems = [versionedDragItemId];
        setInspectorState(proxiedSelectedItems);
      }
      // Manipulate the drag image to indicate the length of the group of items
      if (proxiedSelectedItems.length > 1) {
        const length = selectedItems.length;

        const elem: HTMLElement = document.createElement("div");
        elem.setAttribute("style", dragImageStyleOverride);
        elem.innerHTML = String(length);
        document.body.appendChild(elem);
        e.dataTransfer.setDragImage(elem, 0, 0);
        setTimeout(() => {
          document.body.removeChild(elem);
        }, 0);
      }
      // Add current selection to data transfer
      e.dataTransfer.setData("reorderItemIds", String(proxiedSelectedItems));
      e.dataTransfer.setData("fromSectionId", String(section?.id));

      // Enable drag listeners
      if (!galleryDraggingActive && setDraggingEntity)
        setDraggingEntity({ entity: DragEntities.GALLERY_ITEM });
    },
    [galleryDraggingActive, section?.id, selectedItems, setDraggingEntity, setInspectorState]
  );

  if (!items) return null;

  // eslint-disable-next-line complexity
  const renderedItems = items.map((item, index) => {
    const versionedId = getVersionedItemId(item);
    const selected = selectedItems.includes(versionedId);
    const isEditing = editingItem === item.id;

    const reorderingProps =
      canEditContent && !isEditing
        ? {
            draggable: true,
            onDragStart: handleReorderDragStart,
            onDragEnd: handleReorderDragEnd,
            "data-dragid": versionedId,
          }
        : {};

    const selectionProps = {
      "data-itemid": versionedId,
      selected,
      onClick: e => handleAssetClick(e, item),
    };

    /**
     * Shared vars for determing asset/item based values.
     */
    const prevItem = items[index - 1],
      nextItem = items[index + 1],
      prevItemId = prevItem?.id,
      nextItemId = nextItem?.id;

    /**
     * Switch statement for rendering components by item type
     */
    switch (item.type) {
      case ItemType.asset: {
        const assetType = item.asset.type,
          assetIsFont = assetType === AssetType.textStyle,
          assetIsGenericType = AssetType.genericTypes.has(assetType),
          assetIsAudioType = AssetType.audioTypes.has(assetType),
          width = item.data.displayStyle === "full_width" ? "100%" : `${100 / assetsPerRow}%`,
          isFontOrGenericOrAudioType = assetIsFont || assetIsGenericType || assetIsAudioType,
          hiddenMode = assetViewMode === "hidden",
          buttonPosition = isFontOrGenericOrAudioType ? "center" : "default";

        let assetSpacing = {};

        assetSpacing = spacingUtils.getGalleryAssetSpacing({
          isFullWidthAsset: item.data.displayStyle === "full_width",
          prevAssetIsFullWidth: prevItem?.data.displayStyle === "full_width",
          hiddenMode,
          isFirstItem: index === 0,
        });

        const inspectorItemProps = {
          ...selectionProps,
          key: item.id,
          id: item.id,
          "data-shortid": item.shortId,
          width,
          onDoubleClick: () => handleRouteToAsset(item),
        };

        /**
         * Determine what type of download button should render based
         * on asset type and current galleryAssetViewMode
         */

        const downloadButton =
          selected && selectedItems.length > 1 ? (
            <InAssetMultiDownload
              position={buttonPosition}
              inspectables={inspectables}
              hiddenMode={hiddenMode}
            />
          ) : (
            <InAssetDownload
              position={buttonPosition}
              item={item}
              selected={selected}
              hiddenMode={hiddenMode}
            />
          );

        return (
          <AssetThumb {...assetSpacing} {...inspectorItemProps} {...reorderingProps}>
            <GalleryAssetContent
              canEdit={canEditContent}
              inspectable={{ item, asset: item.asset }}
              assetViewMode={assetViewMode}
              downloadButton={downloadButton}
              selected={selected}
            />
            {renderDropZone(item, index, null, nextItemId, prevItemId)}
          </AssetThumb>
        );
      }
      case ItemType.supportingImage: {
        const supportSpacing = spacingUtils.getGallerySupportSpacing(prevItem?.type, index);
        return (
          <DropZoneWrapper
            key={item.id}
            data-shortid={item.shortId}
            {...supportSpacing}
            {...reorderingProps}>
            <GallerySupportingAsset {...selectionProps} item={item} />
            {renderDropZone(item, index, null, nextItemId, prevItemId)}
          </DropZoneWrapper>
        );
      }
      case ItemType.heading: {
        const headingSpacing = spacingUtils.getGalleryHeadingSpacing(index);
        return (
          <DropZoneWrapper
            key={item.id}
            id={item.id}
            data-shortid={item.shortId}
            {...headingSpacing}
            {...reorderingProps}>
            <InViewTracker id={item.shortId} type="header" />
            <GalleryHeading
              {...selectionProps}
              item={item}
              canEdit={canEditContent}
              isEditing={isEditing}
              hasDefaultValue={item.data.content === insertDataMap[InsertType.heading].data.content}
              onBeginEditing={setEditingItem}
              onFinishEditing={_onFinishEditing}
            />
            {renderDropZone(item, index, null, nextItemId, prevItemId)}
          </DropZoneWrapper>
        );
      }
      case ItemType.codeSnippet: {
        const codeSpacing = spacingUtils.getGalleryCodeSpacing(prevItem?.type, index);
        return (
          <DropZoneWrapper key={item.id} data-shortid={item.shortId} {...reorderingProps}>
            <GalleryCodeSnippet
              {...selectionProps}
              {...codeSpacing}
              item={item}
              canEdit={canEditContent}
              isEditing={isEditing}
              hasDefaultValue={item.data.content === insertDataMap[InsertType.code].data.content}
              onBeginEditing={setEditingItem}
              onFinishEditing={_onFinishEditing}
            />
            {renderDropZone(item, index, null, nextItemId, prevItemId)}
          </DropZoneWrapper>
        );
      }
      case ItemType.guide: {
        const guidePosition = galleryOutline[item.id] ? galleryOutline[item.id].position : "full";
        const secondPreviousItem = items[index - 2];
        const guideSpacing = spacingUtils.getGalleryGuideSpacing(
          prevItem?.type,
          secondPreviousItem?.type,
          guidePosition,
          index
        );
        return (
          <GalleryGuide
            {...selectionProps}
            {...reorderingProps}
            {...guideSpacing}
            data-shortid={item.shortId}
            key={item.id}
            item={item}
            canEdit={canEditContent}
            isEditing={isEditing}
            hasDefaultValue={item.data.content === insertDataMap[InsertType.guide].data.content}
            onBeginEditing={setEditingItem}
            onFinishEditing={_onFinishEditing}
            onInitiateUpload={replaceFileAsset}
            align={guidePosition}>
            {renderDropZone(item, index, guidePosition, nextItemId, prevItemId)}
          </GalleryGuide>
        );
      }
      case ItemType.note: {
        const noteSpacing = spacingUtils.getGalleryNoteSpacing(prevItem?.type, index);
        return (
          <DropZoneWrapper
            key={item.id}
            data-shortid={item.shortId}
            {...noteSpacing}
            {...reorderingProps}>
            <GalleryNote
              {...selectionProps}
              item={item}
              canEdit={canEditContent}
              isEditing={isEditing}
              hasDefaultValue={item.data.content === insertDataMap[InsertType.note].data.content}
              onBeginEditing={setEditingItem}
              onFinishEditing={_onFinishEditing}
            />
            {renderDropZone(item, index, null, nextItemId, prevItemId)}
          </DropZoneWrapper>
        );
      }

      case ItemType.gallery: {
        const gallerySpacing = spacingUtils.getGalleryItemSpacing(prevItem?.type, index);
        return (
          <DropZoneWrapper
            key={item.id}
            data-shortid={item.shortId}
            {...gallerySpacing}
            {...reorderingProps}>
            <ItemPolling item={item}>
              <GalleryItem
                item={item}
                routeToGallery={() => handleRouteToAsset(item)}
                replaceFile={canEditContent ? replaceFileAsset : null}
                {...selectionProps}
              />
            </ItemPolling>
            {renderDropZone(item, index, null, nextItemId, prevItemId)}
          </DropZoneWrapper>
        );
      }
      default:
        return null;
    }
  });

  return (
    <Box position="relative" data-inspector-clear="true">
      {renderKeyboardHandler()}

      {renderEmptySection()}
      {renderContextMenu()}
      {renderInsertAssetMenu()}
      <ItemsWrapper
        ref={itemWrapperRef}
        data-inspector-clear="true"
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}>
        {renderedItems}
      </ItemsWrapper>
    </Box>
  );
};

export default React.memo(GalleryItems, function areEqual(prevProps, nextProps) {
  const draggingActiveChanged = prevProps.galleryDraggingActive !== nextProps.galleryDraggingActive;
  const sectionChanged = prevProps.section?.id !== nextProps.section?.id;
  const sectionNameChanged = prevProps.section?.name !== nextProps.section?.name;
  const assetViewModeChanged = prevProps.assetViewMode !== nextProps.assetViewMode;
  const inspectorItemsChanged = () => !_isEqual(prevProps.selectedItems, nextProps.selectedItems);
  const itemsChanged = () => !_isEqual(prevProps.items, nextProps.items);

  return (
    !sectionChanged &&
    !sectionNameChanged &&
    !assetViewModeChanged &&
    !draggingActiveChanged &&
    !inspectorItemsChanged() &&
    !itemsChanged()
  );
});
