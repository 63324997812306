import { API, PortalMigratePreview, camelize } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  spaceId: number;
};

const [usePreviewMigratePortals, fetchPreviewMigratePortals] = createQueryAction<
  Args,
  { previewPortals: PortalMigratePreview[] }
>(
  {
    entity: "portals",
    action: "fetchPreviewMigratePortals",
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
    denormalize: false,
  },
  async ({ args }) => {
    const res = await API.call<{ previewPortals: PortalMigratePreview[] }>({
      endpoint: `spaces/${args.spaceId}/migrate/preview`,
      method: "GET",
    });

    return { result: camelize(res.result), entities: {} };
  }
);

export default usePreviewMigratePortals;
export { fetchPreviewMigratePortals };
