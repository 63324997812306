import {
  type PortalItem,
  type Section,
  type KitVersion,
  type APIResponse,
  buildURL,
} from "@thenounproject/lingo-core";
import { fetchPortalItem } from "@redux/actions/portalItems/usePortalItem";
import { fetchSection } from "@redux/actions/sections/useSection";
import type { Middleware, UnknownAction } from "@reduxjs/toolkit";
import { type AppStore } from "@redux/store/reduxStore";
import { fetchKitVersion } from "@redux/actions/kitVersions/useKitVersion";
import { parseISO } from "date-fns";
import { duplicateKit } from "@redux/actions/kits/useDuplicateKit";
import { duplicateSection } from "@redux/actions/sections/useDuplicateSection";
import { pollForDuplicateKit } from "@actions/kits/usePollForDuplicateKit";
import { showNotification } from "@redux/actions/useNotifications";
import { selectSpace } from "@redux/selectors/entities/spaces";
import { selectVersion } from "@redux/selectors/entities/kitVersions";
import { fetchKitOutline } from "@redux/actions/kitVersions/useKitOutline";
import { selectSection } from "@redux/selectors/entities/sections";
import { selectKit } from "@redux/selectors/entities/kits";

export const KIT_DUPLICATE_DELAY = 2500;
export const PENDING_SECTION_DELAY = 1000;
export const ASSET_POLL_DELAY = 5000;

const polling: Middleware = (store: AppStore) => next => (action: UnknownAction) => {
  kitVersionPolling(store, action);
  processingPortalAssetPolling(store, action);
  kitDuplicationPolling(store, action);
  sectionDuplicationPolling(store, action);
  return next(action);
};

// MARK : Kit Duplication Polling
// -------------------------------------------------------------------------------
function kitDuplicationPolling(store: AppStore, action: UnknownAction) {
  function poll(kitId: string, sourceId: string, spaceId: string | number) {
    setTimeout(() => {
      void store.dispatch(pollForDuplicateKit({ kitId, sourceId, spaceId }));
    }, KIT_DUPLICATE_DELAY);
  }

  if (duplicateKit.fulfilled.match(action)) {
    const {
        meta: {
          arg: { kitId: sourceId },
        },
        payload: { result, entities },
      } = action,
      version = entities.kitVersions[result];
    poll(version.kitId, sourceId, version.spaceId);
  } else if (pollForDuplicateKit.rejected.match(action)) {
    const { spaceId, kitId, sourceId } = action.meta.arg;
    poll(kitId, sourceId, spaceId);
  } else if (pollForDuplicateKit.fulfilled.match(action)) {
    const kit = action.payload.entities.kits[action.payload.result];
    const space = selectSpace(store.getState(), { spaceId: kit.spaceId });
    store.dispatch(
      showNotification({
        message: `“${kit.name}” is ready. `,
        link: {
          text: "View kit.",
          url: buildURL(`/k/${kit.kitId}/`, { space }),
        },
        level: "info",
      })
    );
  }
}

// MARK : Section Duplicate Polling
// -------------------------------------------------------------------------------
function sectionDuplicationPolling(store: AppStore, action: UnknownAction) {
  function poll(spaceId: number, sectionId: string) {
    setTimeout(() => {
      void store.dispatch(fetchSection({ args: { spaceId, sectionId, version: 0 } }));
    }, PENDING_SECTION_DELAY);
  }

  const getSpaceId = (section: Section) => {
    // Try to get spaceID from the version
    const spaceId = selectVersion(store.getState(), {
      kitId: section.kitId,
      version: section.version,
    })?.spaceId;
    if (spaceId) return spaceId;
    // If we duplicate a section to a different kit, we may not have the version
    // in state yet, in that case fallback to the kit itself
    // Version is better though since there can only be one. There could be multiple kits
    // across spaces if it is a shared kit.
    const kit = selectKit(store.getState(), { kitId: section.kitId });
    return kit?.spaceId;
  };

  if (duplicateSection.fulfilled.match(action)) {
    const { entities, result } = action.payload;
    const section = entities.sections[result];
    const spaceId = getSpaceId(section);
    if (section.status === "active") {
      // If the duplication finished immediately, we just need to refresh the outline
      void store.dispatch(fetchKitOutline({ args: { spaceId, kitId: section.kitId, version: 0 } }));
    } else {
      poll(spaceId, section.id);
    }
  } else if (fetchSection.fulfilled.match(action)) {
    const { entities, result } = action.payload;
    const section = entities.sections[result];
    const existingSection = selectSection(store.getState(), { sectionId: section.id, version: 0 });
    if (existingSection?.status !== "pending") return;

    const spaceId = getSpaceId(section);
    if (section.status === "active") {
      void store.dispatch(fetchKitOutline({ args: { spaceId, kitId: section.kitId, version: 0 } }));
    } else {
      poll(spaceId, section.id);
    }
  }
}

function processingPortalAssetPolling(store: AppStore, action: UnknownAction) {
  let portalItems = [];

  const response: APIResponse<string> = (action as any).payload ?? (action as any).response;
  const { entities, result } = response ?? {};

  if (!entities || !result) return;
  if (entities?.portalItems?.[result]) {
    try {
      portalItems = Object.values(entities.portalItems).filter((portalItem: PortalItem) => {
        return entities.assets?.[portalItem.assetId]?.meta?.assetProcessing === "processing";
      });
    } catch {
      // That's ok
    }
  }

  portalItems.forEach((portalItem: PortalItem) => {
    const { id: portalItemId, asset } = portalItem,
      { shareToken: assetToken } = asset ?? {};
    setTimeout(() => {
      void store.dispatch(fetchPortalItem({ args: { portalItemId, assetToken } }));
    }, ASSET_POLL_DELAY);
  });
}

// MARK : Kit Version Polling
// -------------------------------------------------------------------------------
function kitVersionPolling(store: AppStore, action: UnknownAction) {
  // TODO: This used to have some logic to stop polling if the window is hidden.
  // We should possibly bring that back along with only fegtching visible kits, and assets
  let kitVersion: KitVersion;
  if (fetchKitVersion.fulfilled.match(action)) {
    kitVersion = action.payload.entities.kitVersions[action.payload.result];
    if (kitVersion?.status !== "pending") return;
    const dateAdded = parseISO(kitVersion.dateAdded),
      timeSinceCreation = Math.abs(dateAdded.getTime() - Date.now()) / 1000.0;

    if (timeSinceCreation < 60 * 10) {
      // Poll every 2.5 seconds, increasing the delay as time goes on
      // sqrt is a rough but effective way to increase the delay over time
      const delay = Math.max(2500, Math.sqrt(timeSinceCreation) * 1000);
      setTimeout(() => {
        void pollForVersion(kitVersion, store);
      }, delay);
    }
  }
}

async function pollForVersion(kitVersion: KitVersion, store: AppStore) {
  const res = await store.dispatch(
    fetchKitVersion({
      args: {
        kitId: kitVersion.kitId,
        spaceId: kitVersion.spaceId,
        version: kitVersion.version,
      },
    })
  );

  if (fetchKitVersion.fulfilled.match(res)) {
    const version = res.payload.entities.kitVersions[res.payload.result];
    if (version.status !== "active") return;

    const space = selectSpace(store.getState(), { spaceId: version.spaceId });
    const url = buildURL(`/k/${version.kitId}/`, { space }, { v: version.version });

    store.dispatch(
      showNotification({
        message: `“${version.versionIdentifier}” is ready. `,
        link: {
          text: "View kit.",
          url,
        },
        level: "info",
      })
    );
  }
}

export default polling;
