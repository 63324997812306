import React from "react";
import { Item, Asset } from "@thenounproject/lingo-core";
import { usePollItemAsset } from "@redux/actions/items/usePollItemAsset";
import { usePollAsset } from "@redux/actions/assets/usePollAsset";

type ItemProps = {
  item: Item;
  children: React.ReactNode;
};

type AssetProps = {
  asset: Asset;
  children: React.ReactNode;
};

export function ItemPolling({ item, children }: ItemProps) {
  usePollItemAsset(item);

  return <>{children}</>;
}

export function AssetPolling({ asset, children }: AssetProps) {
  usePollAsset(asset);

  return <>{children}</>;
}
