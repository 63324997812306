import React from "react";
import styled from "styled-components";
import { Flex, Icon, utils, Item, Text, ImageView, buildPath } from "@thenounproject/lingo-core";
import { Link } from "react-router-dom";

const { getColor, getBorderRadius } = utils;

const KitButton = styled(Link)`
  display: inline-block;
  width: 100%;
  padding: 8px;
  border-radius: ${getBorderRadius("small")};
  &:hover {
    background-color: ${getColor("grayLighter")};
  }
`;

const InspectorItemKit: React.FC<{ item: Item }> = ({ item }) => {
  function renderKitImage() {
    return (
      <ImageView
        background="grayLightest"
        borderRadius="4px"
        src={item.kit?.images?.cover}
        cover
        width={40}
        height={40}
        icon="kit"
        iconSize={24}
        iconFill="grayDark"
      />
    );
  }

  let path: string;
  if (item.itemId) {
    path = `/a/${item.item?.shortId ?? item.itemId}`;
  } else if (item.sectionId) {
    path = `/s/${item.section?.shortId ?? item.sectionId}`;
  } else {
    path = `/k/${item.kit?.shortId ?? item.kitId}`;
  }

  const url = buildPath(path, {
    space: { id: item.spaceId },
  });

  return (
    <KitButton to={url}>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          {renderKitImage()}
          <Flex flexDirection="column" ml="s" textAlign="left">
            <Text>{item.section?.name ? item.section?.name : "Untitled section"}</Text>
            <Flex alignItems="center">
              <Text color="grayDarkest" font="ui.small">
                {item.kit?.name}
              </Text>
              {item.item?.data?.viewName || item.item?.data?.name ? (
                <>
                  <Icon iconId="navigation.chevron-right" size="16" fill="grayDarkest" />
                  <Text color="grayDarkest" font="ui.small">
                    {item.item?.data?.viewName || item.item?.data?.name}
                  </Text>
                </>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
        <Icon iconId="navigation.chevron-right" />
      </Flex>
    </KitButton>
  );
};

export default InspectorItemKit;
