/**
 * Footer navigation shown just below content.
 */

import React from "react";
import styled from "styled-components";
import { buildURL, Button, Flex, utils } from "@thenounproject/lingo-core";

import PoweredByLingo from "./PoweredByLingo";

const { positioningCSS } = utils;

const FooterNav = styled(Flex).attrs({
  as: "footer",
  pt: "xxxl",
  pb: "l",
  px: "m",
  m: "auto",
  width: "100%",
  maxWidth: "960px",
  background: "white",
  alignContent: "flex-start",
  justifyContent: "space-between",
  alignItems: "center",
  flexShrink: 0,
})`
  @media (max-width: 768px) {
    text-align: center;
    margin: 0 auto;
    display: block;
    flex-direction: column;
    .powered-by {
      ${positioningCSS({ mt: "l" })};
      ${positioningCSS({ mb: "xxxl" })};
    }
  }
`;

const FooterLink = styled(Button).attrs(props => {
  return {
    mr: "m",
    my: "xs",
    buttonStyle: "tertiary",
    theme: {
      ...props.theme,
      primaryColor: "grayDark",
      primaryColorDark: props.theme.primaryColorDark,
    },
  };
})`
  @media (max-width: 991px) {
    display: inline-block;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

function debugError(event: React.MouseEvent<HTMLButtonElement>) {
  if (event.altKey && event.shiftKey) {
    event.preventDefault();
    throw Error("We're still out of tacos");
  }
}

function Footer({ showPoweredBy }: { showPoweredBy?: boolean }) {
  return (
    <FooterNav data-inspector-clear="true">
      <nav>
        <FooterLink onClick={debugError} text="Lingo" link={buildURL()} newWindow />
        <FooterLink text="Help" link="https://help.lingoapp.com/" newWindow />
        <FooterLink text="Legal" link={buildURL("/legal")} newWindow />
      </nav>
      {showPoweredBy && <PoweredByLingo />}
    </FooterNav>
  );
}

export default Footer;
