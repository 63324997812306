import React, { FormEvent, useCallback, useEffect, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import {
  utils,
  SpaceThemeNames,
  useBoolean,
  ContextMenu,
  PopupMenu,
  Flex,
  useDrag,
  Box,
  Section,
  Kit,
  HeadingItem,
  buildURL,
  Text,
  Button,
  copy,
  useNavigation,
} from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";
import { useGetDraggingState } from "@selectors/getters";
import useReorderHeading from "@redux/actions/sections/useReorderHeading";
import useNotifications from "@actions/useNotifications";
import { textItemDragStyle } from "@features/navigation/consts";
import useUpdateItem from "@actions/items/useUpdateItem";
import useDeleteHeadingWithItems from "@actions/items/useDeleteHeadingWIthItems";
import useMoveHeading from "@redux/actions/sections/useMoveHeading";
import useDuplicateHeading from "@redux/actions/sections/useDuplicateHeading";

import {
  AssetCopyTypes,
  SectionCallbackArgs,
} from "@features/content-management/modals/MoveCopyToSectionModal";
import useNavPoint from "@hooks/useNavPoint";

export type KitNavHeaderData = {
  header: HeadingItem;
  section: Section;
  kit: Kit;
  inView: boolean;
  canEdit: boolean;
  shareLink: string;
};

type HeaderWrapperProps = {
  inView: boolean;
};
const getHeaderWrapperThemeStyles = (props: HeaderWrapperProps & utils.ThemedProps) => {
  return (
    {
      [SpaceThemeNames.classic]: {
        borderLeft: `2px solid ${props.inView ? utils.getPrimaryColor(props) : "transparent"}`,
        pl: props.inView ? "8px" : "0px",
      },
      [SpaceThemeNames.scher]: {},
      [SpaceThemeNames.wyman]: {},
    }[props.theme.themeName] || {}
  );
};
const HeaderWrapper = styled(Flex).attrs<HeaderWrapperProps>(props => {
  return {
    transition: "all .3s ease",
    position: "relative",
    ...getHeaderWrapperThemeStyles(props),
  };
})<HeaderWrapperProps>`
  .overflow {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  @media not (hover: none) {
    &:hover {
      .overflow {
        opacity: 1;
      }
    }
  }
`;

type HeadingButtonProps = {
  inView: boolean;
  dragDirection?: "top" | "bottom";
};
const HeadingButton = styled(Box).attrs<HeadingButtonProps>({
  as: "button",
  position: "relative",
  p: "none",
  textAlign: "left",
  width: "100%",
})<HeadingButtonProps>`
  * {
    color: black;
  }
  &:after {
    content: "";
    height: 2px;
    width: 100%;
    top: 0;
    left: 0;
    background: ${props => utils.getPrimaryColor(props)};
    z-index: 10;
    position: absolute;
    opacity: 0;
  }

  &:before {
    content: "";
    height: 2px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: ${props => utils.getPrimaryColor(props)};
    z-index: 10;
    position: absolute;
    opacity: 0;
  }

  ${props =>
    props.dragDirection === "top" &&
    `
  &:after {
    opacity: 1;
  }
`};
  ${props =>
    props.dragDirection === "bottom" &&
    `
  &:before {
    opacity: 1;
  }
`};
`;

const KitNavHeaderItem: React.FC<KitNavHeaderData> = ({
  header,
  section,
  kit,
  inView,
  canEdit,
  shareLink,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [editing, setEditing, setNotEditing] = useBoolean(false);
  const [menuOpen, setMenuOpen, setMenuClosed] = useBoolean(false);
  const [editValue, setEditValue] = React.useState(header.name);
  const { space, portal } = useNavPoint();
  const { showModal } = useShowModal();
  const { showNotification } = useNotifications();
  const navigation = useNavigation();

  const setDragEntity = useSetDraggingEntity();
  const draggingState = useGetDraggingState();
  const [reorderHeading] = useReorderHeading();

  const { themeName, primaryColor } = useTheme();
  const [deleteHeadingWithItems] = useDeleteHeadingWithItems();
  const [updateItem] = useUpdateItem();
  const [moveHeading] = useMoveHeading();
  const [duplicateHeading] = useDuplicateHeading();

  useEffect(() => {
    if (header.name) setEditValue(header.name);
  }, [header.name]);

  useEffect(() => {
    if (editing) {
      inputRef.current.select();
    }
  }, [editing]);

  const {
    label,
    id,
    sectionId,
    // kitId
  } = useMemo(() => {
    return {
      label: header.name,
      id: header.id,
      sectionId: section.id,
      kitId: kit.kitId,
    };
  }, [header, section, kit]);

  /**
   * Header action handlers
   */

  const onEditHeader = useCallback(() => {
    return updateItem({ itemId: id, updates: { data: { content: editValue } } });
  }, [editValue, id, updateItem]);

  const onFinishEditing = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const { error } = await onEditHeader();
      if (!error) {
        setNotEditing();
      }
    },
    [onEditHeader, setNotEditing]
  );

  const onDeleteHeader = useCallback(() => {
    showModal(ModalTypes.CONFIRMATION, {
      title: "Delete Heading",
      message: "Are you sure you want to delete this heading and all of its items?",
      buttonText: "Delete",
      buttonProcessingText: "Deleting...",
      onConfirm: async () => {
        return await deleteHeadingWithItems({ itemId: id });
      },
    });
  }, [deleteHeadingWithItems, id, showModal]);

  const onClickHeader = useCallback(() => {
    const queryParams = {
      kit_token: kit.shareToken || undefined,
      v: section.version,
    };
    const url = buildURL(`/s/${section.shortId}/`, { space, portal }, queryParams, header.shortId);
    navigation.push(url);
  }, [kit.shareToken, section.version, section.shortId, space, portal, header.shortId, navigation]);

  const onDoubleClickHeader = useCallback(() => {
    if (canEdit) {
      setEditing();
    }
  }, [canEdit, setEditing]);

  /**
   * Drag and drop handlers
   */

  const doOnDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    /** Normalize browser UI during drag */
    e.dataTransfer.effectAllowed = "all";
    /** Create or reuse the previous drag image clone */
    const elem: HTMLElement = document.createElement("div");
    elem.setAttribute("style", textItemDragStyle);
    elem.innerHTML = label;
    document.body.appendChild(elem);
    e.dataTransfer.setDragImage(elem, 0, 0);
    e.dataTransfer.setData("navHeadingId", id);
    e.dataTransfer.setData("fromSectionId", sectionId);
    setDragEntity({ entity: DragEntities.KIT_NAV_HEADING });
    setTimeout(() => {
      document.body.removeChild(elem);
    }, 0);
  };

  const doOnDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e?.preventDefault();
    const headingToMove = e.dataTransfer.getData("navHeadingId");
    setDragEntity({ entity: undefined });

    if (headingToMove === id) return;
    if (!headingToMove) return;

    const res = await reorderHeading({
      sectionId: sectionId,
      headingId: headingToMove,
      displayOrder: `${d.dragDirection.vertical === "top" ? "before" : "after"}:${id}`,
    });
    if (res.error) {
      showNotification({ message: res.error.message, level: "error" });
    }
  };

  const d = useDrag<HTMLDivElement>({ dragRef: ref, doOnDragStart, doOnDrop });
  const dragProps = canEdit ? d.dragProps : {};

  const moveHeader = useCallback(
    async ({ fromSectionId, selectedSection, selectedKit }: SectionCallbackArgs) => {
      const res = await moveHeading({
        headingId: header.id,
        fromSectionId,
        toSectionId: selectedSection.id,
      });
      if (res.isSuccess) {
        showNotification({
          message: `Heading moved to`,
          link: {
            text: selectedSection?.name || "Untitled Section",
            url: buildURL(`/s/${selectedSection?.shortId}`, {
              space,
              portal: selectedKit.kitId === kit.kitId ? portal : undefined,
            }),
          },
        });
      }
    },
    [header.id, kit.kitId, moveHeading, portal, showNotification, space]
  );
  const onMove = useCallback(() => {
    showModal(ModalTypes.MOVE_COPY_TO_SECTION, {
      type: "move",
      fromSectionId: section.id,
      callback: moveHeader,
      entityText: header.name,
    });
  }, [header.name, moveHeader, section.id, showModal]);

  const copyHeader = useCallback(
    async ({ selectedSection, copyAs, selectedKit }: SectionCallbackArgs) => {
      const res = await duplicateHeading({
        headingId: header.id,
        toSectionId: selectedSection.id,
        createReferences: copyAs === AssetCopyTypes.reference,
      });
      if (res.isSuccess) {
        showNotification({
          message: `Heading copied to`,
          link: {
            text: selectedSection?.name || "Untitled Section",
            url: buildURL(`/s/${selectedSection?.shortId}`, {
              space,
              portal: selectedKit.kitId === kit.kitId ? portal : undefined,
            }),
          },
        });
      }
    },
    [duplicateHeading, header.id, kit.kitId, portal, showNotification, space]
  );
  const onCopy = useCallback(() => {
    showModal(ModalTypes.MOVE_COPY_TO_SECTION, {
      type: "copy",
      fromSectionId: section.id,
      callback: copyHeader,
      entityText: header.name,
    });
  }, [copyHeader, header.name, section.id, showModal]);

  /**
   * Rendering funcs
   */
  function renderContextMenu() {
    const contextMenuItems = [];
    if (!editing) {
      if (shareLink) {
        contextMenuItems.push(
          <PopupMenu.Section key="share-link">
            <PopupMenu.Item onClick={() => copy(shareLink)} title="Copy share link" />
          </PopupMenu.Section>
        );
      }
      if (canEdit) {
        contextMenuItems.push(
          <PopupMenu.Section key="update-items">
            <PopupMenu.Item key="edit-item" onClick={setEditing} title="Rename heading" />
            <PopupMenu.Item onClick={onMove} title="Move to..." />
            <PopupMenu.Item onClick={onCopy} title="Copy to..." />
          </PopupMenu.Section>
        );

        contextMenuItems.push(
          <PopupMenu.Section key="edit-items">
            <PopupMenu.Item
              key="delete-item"
              onClick={onDeleteHeader}
              title="Delete heading & items"
            />
          </PopupMenu.Section>
        );
      }
    }

    if (contextMenuItems.length === 0) return;
    return (
      <>
        {/* Context menu for right click */}
        <ContextMenu data-testid="nav-context-menu" id={id} parentRef={ref}>
          {contextMenuItems}
        </ContextMenu>
        {/* Context menu for overflow button */}
        {menuOpen && (
          <PopupMenu source={`${id}-overflow-button`} close={setMenuClosed}>
            {contextMenuItems}
          </PopupMenu>
        )}
      </>
    );
  }

  const renderHeaderInput = () => {
    if (editing) {
      return (
        <Flex as="form" onSubmit={onFinishEditing}>
          <input
            ref={inputRef}
            style={{ padding: 0 }}
            type="text"
            value={editValue}
            onBlur={onFinishEditing}
            onChange={e => setEditValue(e.target.value)}
          />
        </Flex>
      );
    }
  };

  const { getColor } = utils;

  const renderHeaderButton = () => {
    const themedTextProps =
      {
        [SpaceThemeNames.scher]: {
          color: inView ? primaryColor : getColor("grayDarkest"),
        },
        [SpaceThemeNames.wyman]: {
          color: inView ? "black" : getColor("grayDarker"),
        },
      }[themeName] || {};

    if (!editing) {
      return (
        <HeadingButton
          ref={ref}
          inView={inView}
          onClick={onClickHeader}
          onDoubleClick={onDoubleClickHeader}
          dragDirection={
            draggingState.entity === DragEntities.KIT_NAV_HEADING
              ? d?.dragDirection?.vertical
              : undefined
          }
          {...dragProps}>
          <Text as="span" transition="color .3s ease" {...themedTextProps}>
            <Box
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "14px",
              }}>
              {label}
            </Box>
          </Text>
        </HeadingButton>
      );
    }
  };

  return (
    <>
      {renderContextMenu()}
      <HeaderWrapper inView={inView}>
        <>
          {renderHeaderInput()}
          {renderHeaderButton()}
        </>
        {!editing && (
          <Button
            className="overflow"
            icon="navigation.overflow"
            size="small"
            p="none"
            position="absolute"
            right={0}
            top={2}
            width={21}
            height={21}
            themeOverrides={{
              primaryColor: "transparent",
              primaryColorDark: "transparent",
              primaryColorTint: "black",
            }}
            data-popup-source={`${id}-overflow-button`}
            onClick={e => {
              e.stopPropagation();
              setMenuOpen();
            }}
          />
        )}
      </HeaderWrapper>
    </>
  );
};

export default React.memo(KitNavHeaderItem);
