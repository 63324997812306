import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { HeadProvider, Link } from "react-head";
import { Box, Flex, Asset, utils } from "@thenounproject/lingo-core";

const { ellipsis, getFont, positioningCSS } = utils;

const SAMPLE_TEXT = "The quick brown fox jumps over the lazy dog";

type TextSampleProps = {
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  fontStyle?: string;
  textTransform?: string;
  textDecoration?: string;
  fontFeatureSettings?: string;
};

const TextSample = styled(Box).attrs<TextSampleProps>(props => {
  return {
    color: props.color || "black",
    style: {
      fontFamily: props.fontFamily,
      fontSize: props.fontSize || "18px",
      fontWeight: props.fontWeight || "normal",
      fontStyle: props.fontStyle || "normal",
      textTransform: props.textTransform || "initial",
      textDecoration: props.textDecoration || "initial",
      fontFeatureSettings: props.fontFeatureSettings || "normal",
    },
  };
})`
  ${ellipsis}
`;

const TextStyleMeta = styled(Flex).attrs({
  py: "xs",
  px: "m",
  color: "grayDark",
})`
  ${getFont("ui.small", null)};
  div {
    ${positioningCSS({ mr: "m" })};
  }
`;

type Props = {
  asset: Asset;
  isThumbnail: boolean;
  fontSize?: string;
  content?: string;
};

export default function FontSample({ isThumbnail, asset, fontSize, content }: Props) {
  const [loaded, setLoaded] = useState(false);

  const fontMeta = asset.meta.font,
    styleMeta = asset.meta.textStyle || {},
    { displayName, stylesheetUrl } = fontMeta,
    displayAsFont = Object.values(styleMeta).filter(m => m).length === 0;

  let sampleText = displayAsFont ? displayName : SAMPLE_TEXT;
  if (content) sampleText = content;

  function getMetaFields() {
    const { size, color, lineHeight, decoration, transform } = styleMeta;

    return [
      { key: "name", value: displayName, suffix: "" },
      { key: "size", value: size, suffix: "" },
      { key: "color", value: color, suffix: "" },
      { key: "lineHeight", value: lineHeight, suffix: " (line height)" },
      { key: "decoration", value: decoration, suffix: "" },
      { key: "transform", value: transform, suffix: "" },
    ].filter(v => v.value);
  }

  function getTextSampleProps() {
    const { name, family, googleVariant, source } = fontMeta,
      { size, color: fontColor, transform, decoration } = styleMeta;

    let textSampleProps: TextSampleProps = {
      fontSize: fontSize || (size ? `${size}px` : null),
      color: fontColor,
      textTransform: transform,
      textDecoration: decoration,
    };

    if (source === "google-fonts" && googleVariant) {
      // Google font display
      // eslint-disable-next-line no-unused-vars
      const [_, weight, style] = googleVariant.match(/^([0-9]*)([a-z]*)$/);
      textSampleProps = {
        ...textSampleProps,
        fontFamily: family,
        fontWeight: weight,
        fontStyle: style,
      };
    } else {
      // Uploaded font display
      textSampleProps = { ...textSampleProps, fontFamily: name };
    }

    return textSampleProps;
  }

  return (
    <HeadProvider>
      <Link
        href={stylesheetUrl}
        rel="stylesheet"
        onLoad={() => {
          setLoaded(true);
        }}
      />

      {loaded ? (
        <Fragment>
          <TextSample {...getTextSampleProps()}>{sampleText}</TextSample>
          {displayAsFont || !isThumbnail ? null : (
            <TextStyleMeta>
              {getMetaFields().map(v => (
                <div key={v.key}>{v.value + v.suffix}</div>
              ))}
            </TextStyleMeta>
          )}
        </Fragment>
      ) : (
        <TextSample>{sampleText}</TextSample>
      )}
    </HeadProvider>
  );
}
