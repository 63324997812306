import { Font } from "@features/theming/types";
import { createQueryAction } from "@redux/actions/actionCreators";
import { API } from "@thenounproject/lingo-core";

type Args = {
  spaceId: number;
};

const [useThemeFonts] = createQueryAction<Args, Font[], Font[]>(
  {
    entity: "spaces",
    action: "fetchThemeFonts",
    denormalize: false,
    condition: arg => Boolean(arg.args.spaceId),
  },
  async ({ args: { spaceId } }) => {
    const res = await API.call<{ fonts: Font[] }>({
      endpoint: `resources/fonts/google-fonts`,
      query: { space_id: spaceId, theme: true },
      method: "GET",
    });

    return { result: res.result.fonts };
  }
);

export default useThemeFonts;
