import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import {
  Box,
  Text,
  SpaceThemeNames,
  Button,
  Flex,
  Kit,
  buildURL,
  KitPermission,
  SpacePermission,
} from "@thenounproject/lingo-core";

import ThemeEditorAccordion from "./ThemeEditorAccordion";
import ThemeEditorOption from "./ThemeEditorOption";
import ThemeFontPicker from "./ThemeFontPicker";
import ThemeColorPicker from "./ThemeColorPicker";
import ThemeSelect from "./ThemeSelect";
import { useThemeDataContext } from "@contexts/ThemeDataProvider";
import {
  SpaceThemeColorOptions,
  SpaceThemeSelectOptions,
  themeSelectOptions,
  unsavedChangesModalTextProps,
} from "@features/theming/types";

import { useSelectSpace } from "@redux/selectors/entities/spaces";
import useThemeFonts from "@hooks/useThemeFonts";
import useNotifications from "@actions/useNotifications";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useUpdateKitTheme from "@redux/actions/kits/useUpdateKitTheme";
import { getThemeOverrides } from "@contexts/ThemeDataContext";
import ThemeEditorPanelHeader from "./ThemeEditorPanelHeader";
import useKitUpsell, { AccessStatus } from "@hooks/useKitUpsell";

const Wrapper = styled(Box).attrs({
  borderLeft: "default",
  flex: "240px 1 0",
  background: "white",
  overflow: "auto",
  height: "100%",
})``;

type Props = {
  kit: Kit;
};

const KitThemeControlPanel = ({ kit }: Props) => {
  const space = useSelectSpace();
  const spaceTheme = space?.theme;
  const { showModal } = useShowModal();
  const { showNotification } = useNotifications();

  const {
    updateEditingTheme: updateTheme,
    themeChanged,
    editingTheme,
    theme,
    endEditing,
    existingTheme,
  } = useThemeDataContext();

  const { data: fonts = [] } = useThemeFonts({ spaceId: space?.id }, { refetchOnMount: true });
  const [updateKitTheme, { isProcessing }] = useUpdateKitTheme();

  const disabled = !themeChanged || isProcessing;
  const newTheme = editingTheme ?? theme;
  const spacePerms = space?.access?.permissions,
    canCustomizeSpaceTheme = spacePerms?.includes(SpacePermission.manageCustomTheme),
    fontAccess = useKitUpsell(KitPermission.manageCustomThemeFont, kit),
    themeAccess = useKitUpsell(KitPermission.manageCustomThemeAppearance, kit);

  useEffect(() => endEditing, [endEditing]);

  const exitConfigureMode = useCallback(() => {
    endEditing();
    window.location.hash = "";
  }, [endEditing]);

  function handleExitEditor() {
    if (themeChanged) {
      return showModal(ModalTypes.CONFIRMATION, {
        ...unsavedChangesModalTextProps,
        onConfirm: exitConfigureMode,
      });
    } else {
      exitConfigureMode();
    }
  }

  const saveTheme = useCallback(async () => {
    if (isProcessing) return null;

    const theme = getThemeOverrides(newTheme, spaceTheme);
    const { error } = await updateKitTheme({
      spaceId: space.id,
      kitId: kit.kitId,
      theme,
    });
    if (error) {
      showNotification({ message: error.message, level: "error" });
    } else {
      showNotification({ message: "Theme updated successfully.", level: "info" });
      exitConfigureMode();
    }
  }, [
    exitConfigureMode,
    isProcessing,
    kit.kitId,
    newTheme,
    showNotification,
    space.id,
    spaceTheme,
    updateKitTheme,
  ]);

  /* Render functions */
  function renderKitSettingsSection() {
    return (
      <>
        <ThemeEditorAccordion title="Asset Backgrounds" openByDefault>
          <ThemeEditorOption title="Colors">
            <Flex flexDirection="column" gap="4px">
              <ThemeColorPicker
                {...{
                  existingTheme: spaceTheme,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.assetLight,
                }}
              />
              <ThemeColorPicker
                {...{
                  existingTheme: spaceTheme,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.assetDark,
                }}
              />
            </Flex>
          </ThemeEditorOption>
        </ThemeEditorAccordion>
        <ThemeEditorAccordion title="Note Highlighting" openByDefault>
          <ThemeEditorOption title="Colors">
            <Flex flexDirection="column" gap="4px">
              <ThemeColorPicker
                {...{
                  existingTheme: spaceTheme,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.noteInfo,
                }}
              />
              <ThemeColorPicker
                {...{
                  existingTheme: spaceTheme,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.noteWarning,
                }}
              />
              <ThemeColorPicker
                {...{
                  existingTheme: spaceTheme,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.noteSuccess,
                }}
              />
            </Flex>
          </ThemeEditorOption>
        </ThemeEditorAccordion>
      </>
    );
  }

  function renderKitStylesText() {
    return (
      <Box>
        <Text font="ui.small" color="grayDarkest">
          Kit styles are inherited from the space-wide theme. Overrides will apply only to this kit.
        </Text>
        {canCustomizeSpaceTheme && (
          <Button
            text="Adjust space theme"
            size="small"
            buttonStyle="tertiary"
            link={buildURL("/settings/editor", { space })}
          />
        )}
      </Box>
    );
  }

  /* Component render */
  return (
    <Wrapper>
      <ThemeEditorPanelHeader
        onSave={saveTheme}
        onCancel={handleExitEditor}
        disabled={disabled}
        isProcessing={isProcessing}
      />
      <Box>
        <ThemeEditorAccordion title="Inherited Styles" openByDefault>
          {renderKitStylesText()}
          <ThemeEditorOption
            title="Theme"
            upgradeBadge={
              themeAccess.accessStatus === AccessStatus.canAccess
                ? null
                : {
                    openUpgradeModal: themeAccess.openUpgradeModal,
                    featureName: "Custom themes",
                    plural: true,
                  }
            }>
            <ThemeSelect<SpaceThemeNames>
              {...{
                newTheme,
                updateTheme,
                themeSelectOption: SpaceThemeSelectOptions.themeName,
                options: themeSelectOptions,
              }}
            />
          </ThemeEditorOption>
          <ThemeEditorOption
            title="Font"
            customizationMessage="to use a custom font"
            upgradeBadge={
              fontAccess.accessStatus === AccessStatus.canAccess
                ? null
                : {
                    openUpgradeModal: fontAccess.openUpgradeModal,
                    featureName: "Custom fonts",
                    plural: true,
                  }
            }>
            <ThemeFontPicker {...{ fonts, newTheme, existingTheme, updateTheme }} />
          </ThemeEditorOption>
          <ThemeEditorOption title="Colors">
            <ThemeColorPicker
              {...{
                existingTheme: spaceTheme,
                newTheme,
                updateTheme,
                themeColorOption: SpaceThemeColorOptions.accent,
              }}
            />
          </ThemeEditorOption>
        </ThemeEditorAccordion>
        {renderKitSettingsSection()}
      </Box>
    </Wrapper>
  );
};

export default KitThemeControlPanel;
