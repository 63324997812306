import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import {
  buildURL,
  AnyObject,
  Text,
  Flex,
  Box,
  Button,
  useNavigation,
  PortalPermission,
  SpacePermission,
  KitPermission,
  utils,
} from "@thenounproject/lingo-core";
import useNavPoint from "@hooks/useNavPoint";

const SpaceNameButton = styled(Button)`
  &:hover {
    color: ${utils.getColor("grayDarker")};
    text-decoration: none;
  }
`;

type NavLogoProps = {
  style?: AnyObject;
};

const HeaderAvatar: React.FC<NavLogoProps> = ({ style: _style = {} }) => {
  const { space, portal, kit } = useNavPoint();
  const avatarUrl = portal
      ? (portal?.images?.avatars?.avatar180 ?? portal?.images?.avatars?.defaultAvatar)
      : space?.avatars?.avatar || space?.avatars?.defaultAvatar,
    imageName = portal?.name ?? space?.name ?? "Lingo",
    logo = (
      <img
        data-test="custom-nav-logo"
        src={avatarUrl}
        style={{ borderRadius: "20px" }}
        alt={`${imageName} avatar`}
        width="40px"
        height="40px"
      />
    );

  const navigation = useNavigation();

  const contextName = portal?.name ?? space?.name;
  const contextUrl = useMemo(() => {
    if (contextName) {
      if (portal?.access?.permissions.includes(PortalPermission.view)) {
        return buildURL("/", { space, portal });
      } else if (!portal && space.access?.permissions.includes(SpacePermission.view)) {
        return buildURL("/dashboard", { space });
      }
    }
  }, [contextName, portal, space]);

  const secondaryContextTitle = kit?.name;
  const secondaryContextUrl = useMemo(() => {
    if (kit?.access?.permissions?.includes(KitPermission.view)) {
      return buildURL(`/k/${kit.urlId}`, { space, portal });
    }
  }, [kit, portal, space]);

  const navigateHome = useCallback(() => {
    navigation.push(contextUrl);
  }, [contextUrl, navigation]);

  function renderSlash() {
    return (
      <Text color="grayLight" as="h1" font="ui.title" mx="s" style={{ fontWeight: 600 }}>
        /
      </Text>
    );
  }

  function renderTextComponent(text: string, currentPage: boolean, url: string) {
    const color = currentPage ? "black" : "grayDark";
    return (
      <Flex justifyContent="flex-start" alignItems="center" overflow="hidden">
        {url ? (
          <SpaceNameButton
            link={url}
            themeOverrides={{ primaryColor: color }}
            fontStyle="ui.title"
            text={text}
            buttonStyle="tertiary"
            truncate
          />
        ) : (
          <Text as="h1" font="ui.title" flex="2" color={color} truncate>
            {text}
          </Text>
        )}
      </Flex>
    );
  }

  return (
    <Flex width="100%" overflow="hidden" flex="0 4 auto">
      <Box mr="8px" height="40px" onClick={contextUrl ? navigateHome : null}>
        {logo}
      </Box>
      {renderTextComponent(contextName, !secondaryContextTitle, contextUrl)}

      {secondaryContextTitle ? (
        <Flex justifyContent="flex-start" alignItems="center" overflow="hidden">
          {renderSlash()}
          {renderTextComponent(secondaryContextTitle, true, secondaryContextUrl)}
        </Flex>
      ) : null}
    </Flex>
  );
};

export default HeaderAvatar;
