import React, { useCallback } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Text, Flex, Box, Icon, utils } from "@thenounproject/lingo-core";
import { useTheme } from "styled-components";
import { StripeError } from "@stripe/stripe-js";

type Props = {
  onTokenCreated: (token: string) => void;
  onChange: (complete: boolean) => void;
  onCardError: (error: StripeError) => void;
  error: string;
};

const CreditCardInput: React.FC<Props> = ({ onTokenCreated, onChange, onCardError, error }) => {
  const stripe = useStripe(),
    elements = useElements();

  const theme = useTheme();
  const fontFamily = theme?.fonts?.system?.family || "Inter";
  const { getColor } = utils;

  const createToken = useCallback(async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { token, error: tokenError } = await stripe.createToken(cardElement);
    if (tokenError && onCardError) onCardError(tokenError);
    if (token) onTokenCreated(token.id);
  }, [elements, onCardError, onTokenCreated, stripe]);

  const _onChange = useCallback(
    change => {
      onChange(change.complete);
    },
    [onChange]
  );
  const cardOptions = {
    classes: { base: "inputesque", focus: "input-focus" },
    style: {
      base: {
        fontFamily: `"${fontFamily}", sans-serif`,
        fontSize: "14px",
        "::placeholder": {
          color: getColor("grayDark"),
        },
      },
      invalid: {
        iconColor: getColor("error"),
        color: getColor("error"),
      },
    },
  };

  return (
    <Box>
      <Box mb="s">
        <Text as="label">Payment info</Text>
      </Box>
      <Box background="grayLightest" p="s" borderRadius="default">
        <CardElement
          onBlur={createToken}
          onChange={_onChange}
          onReady={el => el.focus()}
          options={cardOptions}
        />
      </Box>
      {error ? (
        <Flex ml="s" flexDirection="row" alignItems="center">
          <Icon size="16" fill="error" iconId="error" pr="xs" />
          <Text font="ui.small" color="error" textAlign="left" my="xs">
            {error}
          </Text>
        </Flex>
      ) : null}
    </Box>
  );
};

export default CreditCardInput;
